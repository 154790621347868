import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';
import moment from "moment";
import styled, {keyframes} from 'styled-components';

import {
  requestBuyin,
  requestGetHandCuttingList,
  requestLeaveRoom,
  requestMyCards,
  requestMyStatusInRoom,
  requestRequestEmoji,
  requestRoomInfo,
  requestSetBlindWait,
  requestTryBet,
  requestStandUpRoom,
  requestMoveRingCancel,
  requestCurrentRoom,
  requestOpenRabbitHuntingCard,
  requestUseExtendTimeItem,
  requestUseOpenCard,
  requestAddonAccept
} from '../api';
import {useNavigate} from 'react-router-dom';
import {setAckListener as setStartGameShuffleListener} from "../api/from_server_game_startGameShuffle";
import {setAckListener as setAvaiableAddonListener} from "../api/from_server_game_avaiableAddon";
import {setAckListener as setRequestBetListener} from "../api/from_server_game_requestBet";
import {setAckListener as setGameWinnerListener} from "../api/from_server_game_gameWinner";
import {setAckListener as setChangeRoundListener} from '../api/from_server_game_changeRound';
import {setAckListener as setEmitAfterBetListener} from "../api/from_server_game_emitAfterBet";
import {setAckListener as setOpenCommunityCardsListener} from "../api/from_server_game_openCommunityCards";
import {setAckListener as setLeaveJoinRoomSocketListener} from "../api/from_server_game_leaveJoinRoomSocket";
import {setAckListener as setMoveRoomReserveComplateListener} from "../api/from_server_game_moveRoomReserveComplate";
import {setAckListener as setMoveReserveComplateListener} from "../api/from_server_game_moveReserveComplate";
import {setAckListener as setmeStandupSingalListener} from "../api/from_server_game_meStandupSingal";
import {setAckListener as setRefreshPlayersInfoListener} from "../api/from_server_game_refreshPlayersInfo";
import {setAckListener as setAllInShowdownListener} from "../api/from_server_game_allInShowdown";
import {setAckListener as setTableCleanUpListener} from "../api/from_server_game_tableCleanUp";
import {setAckListener as setCollectAnteListener} from "../api/from_server_game_collectAnte";
import {setAckListener as setCollectRakeListener} from "../api/from_server_game_collectRake";
import {setAckListener as setSendEmojiListener} from "../api/from_server_game_sendEmoji";
import {setAckListener as setUsedExtendTimeBroadcastListener} from "../api/from_server_game_usedExtendTimeBroadcast";
import {setAckListener as setUsedOpenCardBroadcastListener} from "../api/from_server_game_usedOpenCardBroadcast";
import {setAckListener as setRefreshRoomStackInfoListener} from "../api/from_server_game_refreshRoomStackInfo";
import {
  BET_TYPE,
  CARD_INFO,
  emitAfterBetModel,
  GamePlayer,
  PlayersBettings,
  RAKE_TYPE,
  requestBetModel,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  RoomInfo,
  winnerModel
} from '../dataset';

import CustomerCenterInGame from "../components/game/CustomerCenterInGame";
import InGameButton from "../components/common/InGameButton";
import ModalContainer from "../components/common/ModalContainer";
import BuyInModal from "../components/BuyInModal";
import useGameLayout from "../hooks/useGameLayout";
import useLoadGame from "../hooks/useLoadGame";
import Player from "../components/game/Player";
import useDialog from "../hooks/useDialog";
import {numCardsByRank, toCardString, wait} from "../utils/common";
import PokerCard from "../components/game/PokerCard";
import FieldPots from "../components/game/FieldPots";
import PlayerPot from "../components/game/PlayerPot";
import ActionButtons from "../components/game/ActionButtons";
import ProfileModal from "../components/ProfileModal";
import GameHistory from "../components/game/GameHistory";
import GameStatusBoard from "../components/game/GameStatusBoard";
import OtherRingStatus from "../components/game/OtherRingStatus";
import GameMenu from "../components/game/GameMenu";
import LeftDrawer from "../components/common/LeftDrawer";
import RightDrawer from "../components/common/RightDrawer";
import RightDrawer2 from "../components/common/RightDrawer";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {parseDatetime} from "../constants/moment";
import {calcBlindUpTime, calcElapsedForRound, calcPlayTime, calcLevel, determineRestTime} from "../utils/tournament";
import SettingModal from "../components/SettingModal";
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {myInfoState} from "../recoil/MyInfo";
import StatusBoard from "../components/game/StatusBoard";
import useWinningRate from "../hooks/useWinningRate";
import {globalLoadingState} from "../recoil/Loading";
import {connectionState} from "../recoil/Connection";
import {playSFX, Sounds, stopBGM, stopSound} from "../utils/sound";
import useOnResume from "../hooks/useOnResume";
import LeaveSettlement from "../components/game/LeaveSettlement";
import LeaveSettlementWhenMovedSpectator from "../components/game/LeaveSettlementWhenMovedSpectator";
import EmoticonSelector from "../components/game/EmoticonSelector";
import ParticleBackground from "../components/game/ParticleBackground";
import {
  betLeftSecState,
  ceremonyCardsState,
  ceremonyRankingState,
  communityCardsState,
  emoticonState,
  myCardsState,
  shiningCardsState,
  hasCacheGameDataState
} from "../recoil/Game";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import LoadingGameResource from "../components/common/LoadingGameResource";


const _ = require('lodash');

// @ts-ignore
import {Hand} from "pokersolver";
import {useTranslation} from "react-i18next";
import {moveChipsSound} from "../utils/chip";
import {gameOptionState} from '../recoil/GameOption';
import HandDescModal from "../components/game/HandDescModal";
import {unreadChatCount} from "../recoil/Chat";

const EmoticonHotKeyWrapper = styled.div<{show: boolean}>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => p.show ? `1`: `0`};
  pointer-events: ${p => p.show ? `auto`: `none`};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 19px;
  z-index: 8;
  align-items: center;
  justify-content: center;
  >.item {
    cursor: pointer;
    width: 36px;
    height: 36px;
    object-fit: contain;
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      width: 56px;
      height: 56px;
    }
    @media ${MEDIA_DESKTOP} {
      width: 56px;
      height: 56px;
    }
  }
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  z-index: 1;
  gap: 8px;
  justify-content: space-between;
  -webkit-justify-content: space-between;

  @media ${MEDIA_MOBILE_LANDSCAPE} {
    padding: 15px 20px;
  }
  @media ${MEDIA_DESKTOP} {
    padding: 30px 40px;
  }

  #roomInfo {
    > div {
      margin-left: 16px;
      margin-top: 0;
      position: initial;
      top: 0;
      left: 0;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 4px;
    @media ${MEDIA_DESKTOP} {
      gap: 10px;
    }
    >.sdfsfx {
      color: #fff;
      font-size: 10px;
      border-radius: 8px;
      border: 1px solid rgb(126, 126, 126);
      padding: 7px;
      background: rgba(26, 72, 113, 0.4);
      @media ${MEDIA_DESKTOP} {
        padding: 18px;
        font-size: 15px;
        border-radius: 16px;
        cursor: pointer;
        &:hover {
          background: rgba(14, 19, 23, 0.7);
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StatusWrap = styled.div`
  position: absolute;
  left: 125px;
  top: 26px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
`;
const StatusPCWrap = styled.div`
  position: absolute;
  padding: 5px;
  left: -100%;
  top: -100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
`;
const LogoWrap = styled.div`
  position: absolute;
  z-index: 1;
  width: 100px;
  top: -100%;
  opacity: 1;
  > img {
    width: 100%;
  }
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 180px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 180px;
  }
`;
const MenuButton = styled.button`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #7E7E7E;
  background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    width: 24px;
    height: 24px;
  }
  > span {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background: #ff1818;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
  }
  @media ${MEDIA_DESKTOP} {
    width: 70px;
    height: 56px;
    border-radius: 16px;
    border: 2px solid #7E7E7E;
    background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
    >img {
      width: 48px;
      height: 48px;
    }
  }
`;
const HandRankingHelpButton = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -82px;
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  object-fit: contain;
  opacity: 1 !important;
  pointer-events: visible;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    bottom: unset;
    left: unset;
    transform: none;
    width: 56px !important;
    height: 56px !important;
    right: 30px;
  }
  @media ${MEDIA_DESKTOP} {
    bottom: unset;
    left: unset;
    transform: none;
    width: 56px !important;
    height: 56px !important;
    right: 30px;
  }
`
const HistoryButton = styled.button`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #7E7E7E;
  background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  > img {
    width: 24px;
    height: 24px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 56px;
    height: 56px;
    border-radius: 16px;
    border: 2px solid #7E7E7E;
    background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
    >img {
      width: 48px;
      height: 48px;
    }
  }
`;

const RabbitHuntCardWrapper = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, #ffb347 0%, #ffcc33  51%, #ffb347  100%);
  background-size: 200% auto;
  border-radius: 4px;
  z-index: 8;
  visibility: hidden;
  opacity: 0;
  will-change: opacity, background;
  transition: opacity 0.3s linear, background 0.3s linear;
  color: #fff;         
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid #523d08;
  justify-content: space-around;
  box-shadow: 1px 2px 1px #1d1603;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-right: 4px;
  @media ${MEDIA_DESKTOP} {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
  }
  
  >.sdfnsdf {
    color: #000;
    font-size: 13px;
    padding-left: 3px;
    padding-right: 8px;
    @media ${MEDIA_DESKTOP} {
      font-size: 15px;
    }
  }
  >img {
    width: 21px;
    height: 21px;
    @media ${MEDIA_DESKTOP} {
      width: 28px;
      height: 28px;
    }
  }
  >div {
    display: flex;
    align-items: center;
    >img {
      width: 20px;
      height: 20px;
    }
    >span {
      padding-left: 4px;
      font-weight: bolder;
      color: #000;
      letter-spacing: -0.4px;
      font-size: 13px;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
      }
    }
  }
  &:hover {
    background-position: right center; 
    text-decoration: none;
  }
 
  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

const FlopCardStartAnimation = keyframes`
  from {
    transform: scale(2);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FlopCardOpenAnimation = keyframes`
  to {
    transform: translateX(0);
  }
`;

const CommunityCards = styled.div<{
  noAnimation: boolean
}>`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  position: absolute;
  z-index: 6;
  align-items: flex-end;

  &:not([data-cards="0"]) {
    animation: ${FlopCardStartAnimation} 0.2s linear;
    ${p => p.noAnimation && `
      animation-duration: 0s !important;
    `};
  }

  > * {
    width: 42px;
    height: 56px;
    opacity: 0;
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      width: 95px;
      height: 133px;
    }
    @media ${MEDIA_DESKTOP} {
      width: 95px;
      height: 133px;
    }
    &[data-open="true"] {
      opacity: 1;
      animation-delay: 0.4s;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;

      ${p => p.noAnimation && `
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      `};

      &:nth-of-type(1) {

      }

      &:nth-of-type(2) {
        transform: translateX(calc(-100% - 2px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(3) {
        transform: translateX(calc(-200% - 4px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(4) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }

      &:nth-of-type(5) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }
    }
  }

  >.rabbitLine {
    position: absolute;
    border: 2px dotted #790000;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;
    top: -3px;
    border-radius: 4px;
    
    @media ${MEDIA_DESKTOP} {
      top: -3px;
    }
  }
  >.line3 {
    opacity: 1;
    width: 140px;
    height: 62px;
    transform: translateX(-46px);
    @media ${MEDIA_DESKTOP} {
      width: 300px;
      height: 139px;
      transform: translateX(-99px);
    }
  }
  >.line2 {
    opacity: 1;
    width: 94px;
    height: 62px;
    transform: translateX(69px);
    @media ${MEDIA_DESKTOP} {
      width: 202px;
      height: 139px;
      transform: translateX(149px);
    }
  }
  >.line1 {
    opacity: 1;
    width: 46px;
    height: 62px;
    transform: translateX(92px);
    @media ${MEDIA_DESKTOP} {
      width: 102px;
      height: 139px;
      transform: translateX(198px);
    }
  }
`;

const WinnerHandRanking = styled.div`
  width: auto;
  height: auto;
  min-width: 158px;
  opacity: 1;
  position: absolute;
  bottom: -11px;
  transform: translateY(100%);
  padding: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1;
  background: linear-gradient(90deg, rgba(24, 24, 24, 0.00) -2.1%, #181818 19.17%, #181818 84.58%, rgba(24, 24, 24, 0.00) 100%);

  @media ${MEDIA_MOBILE_LANDSCAPE} {
    height: 44px;
    min-width: 515px;
  }
  @media ${MEDIA_DESKTOP} {
    height: 44px;
    min-width: 515px;
  }

  > div {
    backdrop-filter: drop-shadow(0 2px 4px #000);
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media ${MEDIA_MOBILE_LANDSCAPE} {
      font-size: 30px;
    }
    @media ${MEDIA_DESKTOP} {
      font-size: 30px;
    }
  }
`;

const DealerButton = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(/images/ic_chip_dealer.png);
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;

  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 30px;
    height: 30px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 30px;
    height: 30px;
  }
`;

const CoinMoveWrapper = styled.div`
  .move-coin {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    background-size: contain;
    opacity: 1;
    z-index: 1;

    * {
      animation: none !important;
    }
  }
`;

const CeremonyDimmer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
`;

const GameTable = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  >.video-background {
    display: none;
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      display: block;
    }
    @media ${MEDIA_DESKTOP} {
      display: block;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > img.game-table {
    width: 125%;
    height: 125%;
    object-fit: contain;
    aspect-ratio: 904/1008;
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      position:fixed;
      width: 100%;
      height: 115%;
      top: -40px;
      object-fit: initial;
    }
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 115%;
      height: 115%;
      top: 0;
      object-fit: contain;
      aspect-ratio: 1626/950;
    }
    pointer-events: none;
    user-select: none;
  }

  > div.table-hole {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
  }
`;

const JoinButtonWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;

  > div {
    width: auto;
  }

  @media ${MEDIA_MOBILE_LANDSCAPE} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    padding: 4px 8px;
  }
  @media ${MEDIA_DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    padding: 4px 8px;
  }
`;

type WinnerCards = {
  [userId: number]: number[]
}
type PlayerAct = {
  [userId: number]: number
}

const RestTimePopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 480px;
  background: linear-gradient(90deg, rgba(24, 26, 29, 0.00) 0%, rgba(24, 26, 29, 0.50) 17.71%, rgba(24, 26, 29, 0.50) 82.32%, rgba(24, 26, 29, 0.00) 100%);
  padding: 8px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  z-index: 99;
`;

const InGameButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 20px 24px;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    min-width: initial;
    width: 100%;
  }
  @media ${MEDIA_DESKTOP} {
    min-width: 860px;
    width: 50%;
  }
`;


const WaitButtonWrapper = styled.div<{show: boolean}>`
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  //show가 아닐때 클릭 막기
  pointer-events: ${p => p.show ? 'visible' : 'none'};
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  bottom: 0px;
  left: 0;
  gap: 5px;
  z-index: 2;
  @media (max-width: 390px) {
    padding: 15px 15px;
  }
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    max-width: 860px;
    padding: 0;
    justify-content: flex-end;
    left: unset;
    right: 6px;
    bottom: 6px;
    gap: 6px;
  }
  @media ${MEDIA_DESKTOP} {
    position: absolute;
    gap: 14px;
    pointer-events: visible;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 24px;
    bottom: 0px;
    left: 0px;
    padding-left: 0;
    padding-right: 60px;
  }

  >.dummy {
    width: 96px;
    @media ${MEDIA_DESKTOP} {
      width: 238px;
    }
  }
  >.dummy2 {
    width: 50px;
    margin-left: 12px;
    @media ${MEDIA_DESKTOP} {
      width: 160px;
    }
  }
`;


// eslint-disable-next-line import/no-anonymous-default-export
function GameLayout(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {openDialog, closeDialog} = useDialog();
  const {
    roomId,
    room,
    players,
    myInfo,
    blind,
    gameStyle,
    isWait,
    updatePlayer,
    updateStackSize,
    maxTableMember
  } = useLoadGame();

  // 대기 BGM 재생
  //useBGM(Sounds.BGM_WAIT, isWait);

  const [setting, setSetting] = useRecoilState(gameOptionState);
  const myProfileInfo = useRecoilValue(myInfoState);
  const connected = useRecoilValue(connectionState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const setEmoticon = useSetRecoilState(emoticonState);
  const setShiningCards = useSetRecoilState(shiningCardsState);
  const setCeremonyCards = useSetRecoilState(ceremonyCardsState);
  const [myCards, setMyCards] = useRecoilState(myCardsState);
  const [betLeftSec, setBetLeftSec] = useRecoilState(betLeftSecState);
  const [communityCards, setCommunityCards] = useRecoilState(communityCardsState);
  const [ceremonyRanking, setCeremonyRanking] = useRecoilState(ceremonyRankingState);
  const [showChipLeader, setShowChipLeader] = useState<boolean>(false);
  const initialized = useRef<boolean>(false);
  const isPlaying = useRef<boolean>(false);
  const [gameStatusBoard, setGameStatusBoard] = useState<boolean>(false);
  const [noRoundAnim, setNoRoundAnim] = useState<boolean>(true);
  const [opendDialogId, setOpendDialogId] = useState<number>(-1);
  const [betData, setBetData] = useState<requestBetModel | null>(null);
  const [showCustomerCenter, setShowCustomerCenter] = useState<boolean>(false);
  const [winners, setWinners] = useState<winnerModel[]>([]);
  const [winnerCards, setWinnerCards] = useState<WinnerCards>({});
  const [opendCards, setOpendCards] = useState<WinnerCards>({});
  const [rabbitHuntingList, setRabbitHuntingList] = useState<Array<number|undefined>>([]);
  const [rabbitHuntingLastHandNumber, setRabbitHuntingLastHandNumber] = useState<number>(0);
  const [pots, setPots] = useState<number[]>([]);
  const [buyInSeat, setBuyInSeat] = useState<number>(-1);
  const [additionalBuyinSeat, setAdditionalBuyinSeat] = useState<number>(-1);
  const [sentAct, setSentAct] = useState<boolean>(false);
  const [playersBetting, setPlayersBetting] = useState<PlayersBettings[]>([]);
  const [playersAct, setPlayersAct] = useState<PlayerAct>({});
  const [timerText, setTimerText] = useState<string>('');
  const [blindTimerText, setBlindTimerText] = useState<string>('');
  const [isRestTime, setRestTime] = useState<boolean>(false);
  const [myRank, setMyRank] = useState<number>(0);
  const [statusAwaitMoveRoom, setStatusAwaitMoveRoom] = useState<number>(0);
  const [isUseExtendTimer, setIsUseExtendTimer] = useState<number>(0);
  const [isGameWinExist, setIsGameWinExist] = useState<boolean>(false);
  const [isUseExtendTimerImmediately, setIsUseExtendTimerImmediately] = useState<number>(0);
  const [totalMember, setTotalMember] = useState<number>(0);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showOtherRingStatus, setShowOtherRingStatus] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showHandDesc, setShowHandDesc] = useState<boolean>(false)
  const [profileUserId, setProfileUserId] = useState<number>(-1);
  const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const orientation = useScreenOrientation();
  const [autoCheckFold, setAutoCheckFold] = useState<boolean>(false);
  const [autoCall, setAutoCall] = useState<boolean>(false);
  const [autoCallAny, setAutoCallAny] = useState<boolean>(false);
  const [autoAction, setAutoAction] = useState<'check/fold' | 'autocall' | null>(null);
  const [lastBet, setLastBet] = useState<number>(0);
  const [roomThirdParty, setRoomThirdParty] = useState<RoomInfo | null>(null);
  const [showAutoAction, setShowAutoAction] = useState<boolean>(false);
  const [showAutoActionForRequest, setShowAutoActionForRequest] = useState<boolean>(false);
  const [showEmoticonSelector, setShowEmoticonSelector] = useState<boolean>(false);
  const [addonDialogInstace, setAddonDialogInstance] = useState<number>(-1);
  const [previousTournamentLevel, setPreviousTournamentLevel] = useState<number>(-1);
  const [showEmotionHotKey, setShowEmotionHotKey] = useState<boolean>(false);
  const showingWinner = useRef<boolean>(false);
  const [innerCommunityCards, setInnerCommunityCards] = useState<number[]>([]);
  const [startRemoveCommunityCards, setStartRemoveCommunityCards] = useState<boolean>(false);
  const [innerLegalActs, setInnerLegalActs] = useState<string[]>([]);
  const [chipLeader, setChipLeader] = useState<GamePlayer | null>(null);
  const [tableStyle, setTableStyle] = useState<number>(-1);
  const [isStandingUp, setIsStandingUp] = useState<boolean>(false);
  
  const refMenu = useRef<any>();
  const ChatCount = useRecoilValue(unreadChatCount);
  
  useEffect(() => {
    if(blind.big > 0){
      if(blind.big <= 1000){
        setTableStyle(setting.tableStyle_LV1 ?? 1)
      }else if(blind.big <= 10000){
        setTableStyle(setting.tableStyle_LV2 ?? 2)
      }else{
        setTableStyle(setting.tableStyle_LV3 ?? 3)
      }
    }

  }, [blind.big, setting]);

  useEffect(() => {
    if(innerCommunityCards.length > 0 && communityCards.length === 0){
      //카드 사라지게 하는 애니

      setStartRemoveCommunityCards(true);
      setTimeout(()=>{
        setStartRemoveCommunityCards(false);
        setInnerCommunityCards(communityCards);
        setRabbitHuntingList([]);
        Array.from(document.getElementsByClassName("rabbitLine")).forEach(element => {
          const classNames = Array.from(element.classList);

          classNames.forEach(className => {
            if (className !== "rabbitLine") {
              element.classList.remove(className);
            }
          });
        });
      }, 2000)
    }
    else {
      setInnerCommunityCards(communityCards);
    }
  }, [communityCards, innerCommunityCards]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room) {
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [room])

  const gameLayout = useGameLayout({
    dealerIndex: players.findIndex(p => p?.seat === room?.buttonPosition),
    maxTableMember: maxTableMember,
    pots: pots,
  });

  const calculator = useWinningRate();
  const showActions = useMemo<boolean>(() => {
    if(myInfo?.waitGame){
      return false;
    }else if (sentAct) {
      return false;
    } else if (betData?.userId !== myInfo?.userId) {
      return false;
    } else if (!betData?.legalAct) {
      return false;
    }

    return true;
  }, [betData?.userId, myInfo?.userId, sentAct, myInfo?.waitGame]);

  const showJoinButtonRing = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.RING && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const showJoinButtonTournament = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.TOURNAMENT && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const isRebuyInAvailable = useMemo<boolean>(() => {
    if (myInfo?.blindWait === true) {
      if (room?.type === ROOM_TYPE.RING) {
        const rake = room?.groupData?.rake;
        const rakeType = room?.groupData?.rakeType;

        if (rakeType === RAKE_TYPE.HAND_RAKE) {
          if (myInfo?.stackSize < rake + blind.big + blind.ante) {
            return true;
          }
        } else if (rakeType === RAKE_TYPE.POT_RAKE) {
          if (myInfo?.stackSize < blind.big + blind.ante) {
            return true;
          }
        }
      } else {
        if (myInfo?.stackSize > 0) {
          return true;
        }
      }
    }

    return false;
  }, [room?.groupData, myInfo?.blindWait, myInfo?.stackSize, blind]);

  const getRabbitHuntCardsLengthNative = (data:any) => {
    let ret = 0;

    data.map((e:any)=> {
      if (e === -1) ret++;
    });

    return ret;
  };

  const getRabbitHuntHideCardsLength = useCallback(() => {
    return getRabbitHuntCardsLengthNative(rabbitHuntingList);
  }, [rabbitHuntingList]);

  const totalPotSize = useMemo<number>(() => {
    const totalBet = playersBetting.reduce((a, v) => a + v.bet, 0);
    const collectedPotSize = pots.reduce((a, v) => a + v, 0);

    return totalBet + collectedPotSize;
  }, [playersBetting, pots]);

  const resetGame = useCallback(() => {
    setMyCards([]);
    setCommunityCards([]);
    setBetData(null);
    setWinners([]);
    setWinnerCards([]);
    setOpendCards([]);
    setPots([]);
    setPlayersBetting([]);
    setSentAct(false);
    setIsGameWinExist(false);
    setPlayersAct({});
    setShiningCards([]);
    setCeremonyCards([]);
    setRabbitHuntingList([]);
    setCeremonyRanking('');
    setIsUseExtendTimerImmediately(0);

    calculator.resetWinningRates();
    // 카드 요소 전부 감추기
    gameLayout.hideAllCards();

    Array.from(document.getElementsByClassName("rabbitLine")).forEach(element => {
      const classNames = Array.from(element.classList);

      classNames.forEach(className => {
        if (className !== "rabbitLine") {
          element.classList.remove(className);
        }
      });
    });
  }, []);

  const refreshGame = useCallback(() => {
    if (roomId >= 0) {
      (async () => {
        initialized.current = false;
        setGlobalLoading(true);
        await requestRoomInfo(roomId);
        await requestMyStatusInRoom(roomId);
        requestMyCards(roomId).then(({cards}) => {
          if (cards) {
            setMyCards(cards);
          }
        });
        setGlobalLoading(false);
      })();
    }
  }, [roomId]);

  useEffect(() => {
    stopBGM();
  }, []);

  useEffect(() => {
    const myInfoSafe:any = myInfo;
    if (myInfoSafe && myInfoSafe.moveReserveRoomId && myInfoSafe.moveReserveRoomId !== -1) {
      setStatusAwaitMoveRoom(1);
    }
    else {
      setStatusAwaitMoveRoom(0);
    }

    if ((myInfoSafe && myInfoSafe.isUseExtendTimer) || isUseExtendTimerImmediately === 1) {
      setIsUseExtendTimer(1);
    }
    else {
      if (showActions) {
        setIsUseExtendTimer(0);
      }
      else {
        setIsUseExtendTimer(1);
      }
    }
  }, [roomId, room, players, myInfo, betData, isUseExtendTimerImmediately]);

  
  useEffect(() => {
    resetGame();
  }, [roomId]);

  useEffect(() => {
    if (connected) {
      refreshGame();
    }
  }, [connected, refreshGame]);

  useOnResume(() => {
    resetGame();
    refreshGame();
  }, [refreshGame]);

  // 진행중인 게임 데이터 구성
  useEffect(() => {
    if (!room || initialized.current) {
      return;
    }

    const acts: PlayerAct = {};
    const betting: PlayersBettings[] = [];
    
    for (let player of room.players) {
      const isFolded = player.status === ROOM_JOIN_STATUS.FOLD
      acts[player.userId] = isFolded ? BET_TYPE.FOLD : player.lastAction;
      betting.push({
        id: player.userId,
        bet: player.bet,
        ante: player.ante,
        rake: player.rake,
        stackSize: player.stackSize,
        folded: isFolded,
        prevStackSize: player.prevStackSize,
      });
    }
    setPlayersAct(acts);
    setPlayersBetting(betting);

    setCommunityCards(room.cards || []);
    setOpendCards(room.opendCards || {});

    const pots = room.pots || [];
    // ante가 남아있는 경우 팟에 포함시키기
    const totalAnte = betting.reduce((a, v) => a + v.ante, 0);
    if (totalAnte > 0 && pots.length === 1 && pots[0] === 0) {
      pots[0] = pots[0] + totalAnte
    }
    setPots(pots);

    if (room.roomStatus === ROOM_STATUS.INGAME) {
      isPlaying.current = true;
      gameLayout.showAllCards();
    }

    if (room.type === ROOM_TYPE.TOURNAMENT) {
      // 휴식시간 세팅
      const startedAt = parseDatetime(room.groupData.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = room.groupData.timeStructure;
      if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
        if (room?.currentRound === null || room?.currentRound === undefined) {
          if (!showingWinner.current) {
            setRestTime(true);
            gameLayout.hideAllCards();
          }
        } else {
          setRestTime(false);
        }

      }
    }

    initialized.current = true;
  }, [room]);

  const playFlipSound = async () => {
    const soundArr = [Sounds.SFX_CARD_DIS,Sounds.SFX_CARD_DIS,Sounds.SFX_CARD_DIS,Sounds.SFX_FLOP]
    for(let i = 0; i < 4; i++){
      await wait(300)
      playSFX(soundArr[i])
    }
  }

  useEffect(() => {
    if (room) {
      setRoomThirdParty(room);
    }
  }, [room]);
  
  // 커뮤니티 카드 플립 사운드 재생
  useEffect(() => {
    if(communityCards.length === 3){
      playFlipSound();
    }else if (communityCards.length > 3) {
      playSFX(Sounds.SFX_RIVER_CARD_OPEN);
    }
  }, [communityCards]);

  // 타이머 사운드 재생
  useEffect(() => {
    if (betLeftSec && betData?.userId === myInfo?.userId) {
      if (betLeftSec <= 4) {
        playSFX(Sounds.SFX_TIMER_ALERT);
      }
    }
  }, [betData, myInfo?.userId, betLeftSec])

  // 내 차례 사운드 재생, 내 차레 끝나면 타이머 사운드 중지
  useEffect(() => {
    if (betData && myInfo && betData?.userId === myInfo?.userId) {
      //playSFX(Sounds.SFX_MYTURN);
    } else {
      stopSound(Sounds.SFX_TIMER_ALERT);
    }
  }, [betData?.userId, myInfo?.userId]);

  // 메이드, 프리미엄 카드 반짝임 효과
  useEffect(() => {
    if (myCards.length !== 2) {
      return;
    }

    if (communityCards.length === 0) {
      // 프리미엄 카드 효과
      // AA, KK, QQ, JJ, AK, 1010, Aqs, Ajs, KQs
      const cards = [CARD_INFO[myCards[0]], CARD_INFO[myCards[1]]]
      const [valueL, suitL] = cards[0].split('');
      const [valueR, suitR] = cards[1].split('');
      const values = [valueL, valueR].sort().join('');

      if (values === 'AA' || values === 'KK' || values === 'QQ' || values === 'JJ' || values === 'TT' || values === 'AK') {
        setShiningCards(cards);
      } else if ((values === 'AQ' || values === 'AJ' || values === 'KQ') && suitL === suitR) {
        setShiningCards(cards);
      }
    } else {
      // 메이드 효과
      const solved = Hand.solve([
        ...myCards.map(x => CARD_INFO[x]),
        ...communityCards.map(x => CARD_INFO[x])
      ]);
      if (solved.rank >= 4) {
        const numCards = numCardsByRank(solved.rank)
        const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit)).slice(0, numCards);
        setShiningCards(targetCards);
      }
    }
  }, [myCards, communityCards]);

  useEffect(() => {
    setStartGameShuffleListener(async (data: {
      roomId: number,
      userId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      if (isRestTime) {
        setRestTime(false);
      }
      showingWinner.current = false;
      setMyCards([]);
      isPlaying.current = true;

      const chipLeader = players.filter(x => {
        const disabled = !x && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
        return !disabled
      }).sort((a, b) => (b?.stackSize ?? 0)  - (a?.stackSize ?? 0))[0];
      setChipLeader(chipLeader ?? null);

      setShowChipLeader(true);
      // 모든 카드 가운데로 모으기
      gameLayout.resetPlayersCards();

      // 딜러버튼 위치부터 시계방향으로 배열 만들어서 카드 딜링
      let arr = players.filter(x => x !== undefined) as GamePlayer[];
      const startIndex = arr.findIndex(x => x?.userId === data?.userId);
      arr = arr.splice(startIndex).concat(arr);
      await gameLayout.dealCardsToPlayers(arr);

      // SB/BB
      const playersBetting: PlayersBettings[] = [];
      for (let player of players) {
        if (player && player.status === ROOM_JOIN_STATUS.PLAYING && player.bet > 0) {
          playSFX(moveChipsSound(player.bet));
          playersBetting.push({
            id: player.userId,
            bet: player.bet,
            ante: player.ante,
            rake: player.rake,
            folded: false,
            stackSize: player.stackSize,
            prevStackSize: player.prevStackSize,
          });
          setPlayersBetting(playersBetting);
        }
      }

      // 내 카드 세팅
      if (myInfo && myInfo.seat >= 0) {
        const {cards} = await requestMyCards(roomId);
        setMyCards(cards);
      }
    });
    setAvaiableAddonListener(async (data: {
      roomId: number,
      groupId: number,
      addonPrice: number,
      addonChip: number
    }) => {
      if (addonDialogInstace !== -1) {
        closeDialog(addonDialogInstace);
        setAddonDialogInstance(-1);
      }

      const c = openDialog({
        title: '애드온 안내',
        text: `${data.addonPrice.toLocaleString()} 금액에 ${data.addonChip.toLocaleString()} 을 바이인하실 수 있습니다.<br/>구매하시시겠습니까?<br/>(보유금액에서 차감됩니다.)`,
        confirm: true,
        confirmText: '구매',
        cancelText: '취소',
        onConfirm: async () => {
          playSFX(Sounds.SFX_WINDOW_OPEN, true);
          const r = await requestAddonAccept(data.groupId, data.roomId);

          if (r.result < 0) {
            openDialog({
              title: '안내',
              text: r.message,
              onConfirm: () => {
                playSFX(Sounds.SFX_WINDOW_CLOSE);
              }
            });
          }
          else {
            openDialog({
              title: '안내',
              text: '애드온 구입이 완료되었습니다.',
              onConfirm: () => {
                playSFX(Sounds.SFX_WINDOW_CLOSE);
              }
            });
          }
        },
        onCancel: async () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        }
      });

      setAddonDialogInstance(c);
    });
    setRequestBetListener(async (data: requestBetModel) => {
      if (roomId != data.roomId) {
        return;
      }
      if (!isPlaying.current) {
        isPlaying.current = true;
      }
      setShowChipLeader(true);
      setShowAutoAction(true)
      setShowAutoActionForRequest(true)
      if (!betData || data.userId !== betData.userId) {
        setBetData(data);
      }

      if (data.leftSec === null || data.leftSec === undefined) {
        setBetLeftSec(undefined);
      } else {
        setBetLeftSec(data.leftSec);
      }

      if (data.userId === myInfo?.userId) {
        if (autoCall || autoCallAny) {
          if (data.legalAct.includes('call')) {
            await handleTryBet(BET_TYPE.CALL, 0)

          } else if (data.legalAct.includes('check')) {
            await handleTryBet(BET_TYPE.CHECK, 0)
          }
        } else {
          if (autoCheckFold) {
            if (data.legalAct.includes('check')) {
              await handleTryBet(BET_TYPE.CHECK, 0)
            } else {
              await handleTryBet(BET_TYPE.FOLD, 0)
            }
          }
        }
        setAutoCall(false)
        setAutoCallAny(false)
        setAutoCheckFold(false)
      }
      if (sentAct && data.userId !== myInfo?.userId) {
        setSentAct(false);
      }
    });
    setEmitAfterBetListener(async (data: {
      model: emitAfterBetModel,
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      const model = data.model;

      if (model.lastBetStatus) {
        const {
          userId,
          type,
          bet
        } = model.lastBetStatus;
        
        if (type === BET_TYPE.CHECK) {
          const checkSound = [Sounds.SFX_CHECK_1, Sounds.SFX_CHECK_2]
          playSFX(checkSound[Math.floor(Math.random() * checkSound.length)], true)
          playSFX(Sounds.SFX_ACTION_CHECK);
        } else if (type === BET_TYPE.CALL) {
          const soundList = [Sounds.SFX_BET_1,Sounds.SFX_BET_2,Sounds.SFX_BET_3,Sounds.SFX_BET_4]
          playSFX(soundList[Math.floor(Math.random() * soundList.length)]);
          playSFX(Sounds.SFX_ACTION_CALL);
        } else if (type === BET_TYPE.RAISE || type === BET_TYPE.BET) {
          const soundList = [Sounds.SFX_BET_1,Sounds.SFX_BET_2,Sounds.SFX_BET_3,Sounds.SFX_BET_4]
          playSFX(soundList[Math.floor(Math.random() * soundList.length)])
          playSFX(Sounds.SFX_ACTION_RAISE);
        } else if (type === BET_TYPE.ALLIN) {
          playSFX(Sounds.SFX_ALLIN, true)
          playSFX(Sounds.SFX_ACTION_ALLIN);
        } else if (type === BET_TYPE.FOLD) {
          playSFX(Sounds.SFX_FOLD, true)
          playSFX(Sounds.SFX_ACTION_FOLD);
        }

        if (opendDialogId !== -1) {
          closeDialog(opendDialogId);
          setOpendDialogId(-1);
        }

        setPlayersAct(produce((d) => {
          d[userId] = type;
        }));

        if (bet > 0) {
          playSFX(moveChipsSound(bet));
        }

        setBetData(null);
      }
      if (model.playerBettings) {
        setPlayersBetting(model.playerBettings);

        // 스택사이즈 업데이트
        for(let {id, stackSize} of model.playerBettings) {
          updateStackSize(id, stackSize);
        }
      }
      // 올인인 경우 팟 반영
      if (model.isAllIn && model.pots) {
        setPots(model.pots.map(x => x.amount + x.potRake));
        gameLayout.moveCoinToGamePot();
        setPlayersBetting([]);
      }
    });
    setChangeRoundListener(async (data: {
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      setShowChipLeader(true);
      if (room) {
        if (room.currentRound !== null && room.currentRound !== undefined) {
          showingWinner.current = false;
        }
        // changeRound 될때 자동체크폴드 콜  초기화
        setAutoCall(false)
        setAutoCallAny(false)
        setAutoCheckFold(false)

        setShowAutoAction(false);
        setNoRoundAnim(false);

        const prevTotalPot = pots.reduce((a, v) => a + v, 0);
        const newTotalPot = room.pots.reduce((a, v) => a + v, 0);
        if (newTotalPot > prevTotalPot) {
          gameLayout.moveCoinToGamePot();
          setPots(room.pots || []);
        }
        setPlayersBetting([]);

        // 폴드 외 액션 삭제
        setPlayersAct(produce((d) => {
          for (let userId in d) {
            if (d[userId] !== BET_TYPE.FOLD) {
              delete d[userId];
            }
          }
        }));
        await wait(500);
        setCommunityCards(room.cards || []);
      }
    });
    setAllInShowdownListener(async (data: {
      roomId: number,
      wholeCards: {
        userId: number,
        holeCards: number[]
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }
      setShowChipLeader(true);
      // 승률 설정 전 리셋
      calculator.resetWinningRates();
      const innerWinnerCards: WinnerCards = {};
      for (let {
        userId,
        holeCards
      } of data.wholeCards) {
        innerWinnerCards[userId] = holeCards;
        calculator.addPlayer(userId, holeCards);
      }
      if (innerWinnerCards) {
        setWinnerCards(prev => {
          return {
            ...prev,
            ...innerWinnerCards
          }
        })
      }
    });
    setOpenCommunityCardsListener(async (data: {
      roomId: number;
      cards: number[];
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      setShowChipLeader(true);
      setCommunityCards(data.cards);
      await calculator.setCommunityCard(data.cards);

      setTimeout(() => {
        calculator.updateWinningRate();
      }, 1000);
    });
    setGameWinnerListener(async (data: {
      roomId: number;
      winners: winnerModel[];
      players: GamePlayer[];
      prize: number;
      wholeCards: {
        [key: number]: number[]
      };
      tournamentRankingList: {
        userId: number,
        stackSize: number,
        ranking: number
      }[];
      totalMemberCount: number;
      rabbitHunting:Array<number|undefined>;
      handNumber: number;
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        if (data.rabbitHunting) {
          const lineNumber = getRabbitHuntCardsLengthNative(data.rabbitHunting);

          if (lineNumber > 0 && myInfo && myInfo.type === "players" && data.handNumber) {
            setRabbitHuntingList(data.rabbitHunting);
            setRabbitHuntingLastHandNumber(data.handNumber);

            setTimeout(()=> {
              Array.from(document.getElementsByClassName("rabbitLine")).forEach(element => {
                if (!element.classList.contains("line" + lineNumber)) {
                  element.classList.add("line" + lineNumber);
                }
              });
            }, 500);
          }
        }

        setIsGameWinExist(true);
        setShowChipLeader(true);
        const chipLeader = players.filter(x => {
          const disabled = !x && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
          return !disabled
        }).sort((a, b) => (b?.stackSize ?? 0)  - (a?.stackSize ?? 0))[0];
        setChipLeader(chipLeader ?? null);
        // 위너 설정
        setWinnerCards(data.wholeCards || {});
        await wait(1000);

        setWinners(data.winners);
        const allPlayerCount = data.players.filter(x => [ROOM_JOIN_STATUS.PLAYING, ROOM_JOIN_STATUS.FOLD].includes(x.status)).length;
        const foldPlayerCount = data.players.filter(x => x.status === ROOM_JOIN_STATUS.FOLD).length;
        //내가 이겼을때 사운드
        if(data.winners.find(x => x.userId === myInfo?.userId)){
          //상대방이 다 폴드해서 이긴경우
          //즉 폴드플레이어가 전체 -1 인 경우
          if(allPlayerCount - 1 === foldPlayerCount){
            playSFX(Sounds.SFX_WIN_FOLD)
          }else{
            playSFX(Sounds.SFX_OPEN_WIN)
          }
        }else{ //내가 졌을때 사운드
          //내가 폴드해서 진경우
          if(allPlayerCount - 1 === foldPlayerCount){
            playSFX(Sounds.SFX_FOLD_LOSE)
          }else{
            playSFX(Sounds.SFX_OPEN_LOSE)
          }
        }
        if (data.wholeCards) {
          setWinnerCards(prev => {
            return {
              ...prev,
              ...data.wholeCards
            }
          })
          playSFX(Sounds.SFX_SHOW_HIDE_CARDS);
        }
        calculator.resetWinningRates();
        await gameLayout.moveCoinToWinners(data.winners);

        // 스택 업데이트
        for(let {userId, stackSize} of data.players) {
          updateStackSize(userId, stackSize);
        }

        setPots([]);
        setTimeout(()=>{
          setShowEmotionHotKey(true);
          setTimeout(()=>{
            setShowEmotionHotKey(false);
          }, 2000)
        }, 1000)
        setTimeout(() => {
          showingWinner.current = false;
        }, 2000)
        // 토너먼트 처리
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 랭킹 세팅
          if (data.tournamentRankingList) {
            const myRanking = data.tournamentRankingList.find(x => x.userId === myInfo?.userId);
            if (myRanking) {
              setMyRank(myRanking.ranking);
              setTotalMember(data.totalMemberCount);
            }
          }

          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            if (room?.currentRound === null || room?.currentRound === undefined) {
              setRestTime(true);
            } else {
              setRestTime(false);
            }
          }
        }
      }
    });
    setRefreshRoomStackInfoListener(async(data: {
      roomId: number;
      players: any[];
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      for(let {id, stackSize} of data.players) {
        updateStackSize(Number(id), stackSize);
      }
    });
    setTableCleanUpListener(async () => {
      resetGame();
      isPlaying.current = false;
    });
    setmeStandupSingalListener(async (data:any) => {
      setIsStandingUp(data && data.roomId === roomId);
    });
    setMoveReserveComplateListener(async (data:any) => {
      if (data.reason === 1) {
        setStatusAwaitMoveRoom(0);
        resetGame();
        refreshGame();

        navigate("/");
        setTimeout(()=> {
          navigate("/game?id=" + data.roomId);
        }, 50);
      }
    });
    setLeaveJoinRoomSocketListener(async (data: any) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.reason == -1) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('안내'),
          text: t('게임룸이 닫혀 대기실로 이동되었습니다.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -2) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('안내'),
          text: t('장시간 입력이 없어 대기실로 이동되었습니다.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -4) {
        playSFX(Sounds.SFX_TOURNAMENT_LOSE);
        openDialog({
          title: t('안내'),
          text: t('칩을 모두 잃어 대기실로 이동되었습니다.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      } else if (data.reason == -5) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: t('안내'),
          text: t('정산이 완료된 상태여서 나가집니다.'),
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      }
      
      navigate('/');
    });
    setRefreshPlayersInfoListener(async (data: {
      roomId: number,
      players: GamePlayer[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const joinedUser = Object.values(data.players).find(x => x !== null);
      if (!joinedUser) {
        playSFX(Sounds.SFX_GAME_OUT);
      } else if (joinedUser.blindWait) {
        playSFX(Sounds.SFX_GAME_IN);
      }

      for (let seat in data.players) {
        const player = data.players[seat];

        if (player) {
          if (player.status === ROOM_JOIN_STATUS.FOLD) {
            // 유저가 나갔다가 다시 들어와서 FOLD 처리
            setPlayersAct(produce(d => {
              d[player.userId] = BET_TYPE.FOLD;
            }))
          }
          // else if (isPlaying.current) {
          //   player.status = ROOM_JOIN_STATUS.BUYIN_READY;
          // }
        }


        await updatePlayer(Number(seat), player);
      }

      if (isPlaying.current && (room?.type !== ROOM_TYPE.TOURNAMENT || !isRestTime)) {
        gameLayout.showAllCards();
      }
    });
    setCollectAnteListener(async (data: {
      roomId: number,
      antePlayers: {
        userId: number,
        ante: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.antePlayers) {
        for (let {
          userId,
          ante
        } of data.antePlayers) {
          setPots(produce(d => {
            d[0] = (d[0] || 0) + ante;
          }));
          await gameLayout.moveCoinAnte(userId, ante);
        }
      }
    });
    setCollectRakeListener(async (data: {
      roomId: number,
      rakePlayers: {
        userId: number,
        rake: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.rakePlayers) {
        for (let {
          userId,
          rake
        } of data.rakePlayers) {
          await gameLayout.moveCoinRake(userId, rake);
        }
      }
    });
    setUsedExtendTimeBroadcastListener(async (data: {
      userId: number,
      roomId: number,
      addCount: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (myInfo?.userId === data.userId) {
        setIsUseExtendTimerImmediately(1);
      }

      if (betLeftSec) {
        setBetLeftSec(betLeftSec + data.addCount);
      }
    });
    setUsedOpenCardBroadcastListener(async (data: {
      roomId: number,
      opendCards:any[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      setOpendCards(data.opendCards);
    });
    setSendEmojiListener(async (data: {
      roomId: number,
      userId: number,
      emojiId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (!(myProfileInfo && typeof myProfileInfo.emojiBans === 'object'))
        return;

      const myBanList = myProfileInfo.emojiBans;

      const {
        emojiId,
        userId
      } = data

      //이모티콘 차단 상대가 이모지 보낸거는 무시한다.  
      if (myBanList.indexOf(userId) > -1) 
        return ;
      
      setEmoticon(produce(d => {
        d[userId] = emojiId;
      }));

      if (emojiId === 1) {
        playSFX(Sounds.SFX_EMOJI_1, true, true);
      }
      else if (emojiId === 2) {
        playSFX(Sounds.SFX_EMOJI_2, true, true);
      }
      else if (emojiId === 3) {
        playSFX(Sounds.SFX_EMOJI_3, true, true);
      }
      else if (emojiId === 4) {
        playSFX(Sounds.SFX_EMOJI_4, true, true);
      }
      else if (emojiId === 5) {
        playSFX(Sounds.SFX_EMOJI_5, true, true);
      }
      else if (emojiId === 6) {
        playSFX(Sounds.SFX_EMOJI_6, true, true);
      }
      else if (emojiId === 7) {
        playSFX(Sounds.SFX_EMOJI_7, true, true);
      }
      else if (emojiId === 8) {
        playSFX(Sounds.SFX_EMOJI_8, true, true);
      }
      else if (emojiId === 9) {
        playSFX(Sounds.SFX_EMOJI_9, true, true);
      }
      else if (emojiId === 10) {
        playSFX(Sounds.SFX_EMOJI_10, true, true);
      }
      else if (emojiId === 11) {
        playSFX(Sounds.SFX_EMOJI_11, true, true);
      }
      else if (emojiId === 12) {
        playSFX(Sounds.SFX_EMOJI_12, true, true);
      }
      else if (emojiId === 13) {
        playSFX(Sounds.SFX_EMOJI_13, true, true);
      }
      else if (emojiId === 14) {
        playSFX(Sounds.SFX_EMOJI_14, true, true);
      }
      else if (emojiId === 15) {
        playSFX(Sounds.SFX_EMOJI_15, true, true);
      }
      else if (emojiId === 16) {
        playSFX(Sounds.SFX_EMOJI_16, true, true);
      }
      else if (emojiId === 17) {
        playSFX(Sounds.SFX_EMOJI_17, true, true);
      }
      else if (emojiId === 18) {
        playSFX(Sounds.SFX_EMOJI_18, true, true);
      }

      // 3초 뒤 해당 플레이어의 이모티콘 삭제
      setTimeout(() => {
        setEmoticon(produce(d => {
          if (d[userId] === emojiId) {
            delete d[userId];
          }
        }));
      }, 3000);
    });
    return () => {
      setStartGameShuffleListener(null);
      setAvaiableAddonListener(null);
      setRequestBetListener(null);
      setEmitAfterBetListener(null);
      setChangeRoundListener(null);
      setAllInShowdownListener(null);
      setOpenCommunityCardsListener(null);
      setGameWinnerListener(null);
      setTableCleanUpListener(null);
      setMoveReserveComplateListener(null);
      setLeaveJoinRoomSocketListener(null);
      setmeStandupSingalListener(null)
      setRefreshPlayersInfoListener(null);
      setCollectAnteListener(null);
      setCollectRakeListener(null);
      setSendEmojiListener(null);
      setUsedExtendTimeBroadcastListener(null);
      setUsedOpenCardBroadcastListener(null);
    };
  });
  
  //자동 콜 풀리는 로직
  useEffect(() => {
    if (room?.currentBet) {
      if (lastBet !== room?.currentBet) {
        setAutoCall(false)
        setLastBet(0)
      }
    } else {
      setAutoCall(false)
      setLastBet(0)
    }
  }, [room?.currentBet])


  useEffect(() => {
    if(betData?.userId === myInfo?.userId){
      setTimeout(()=>{
        setInnerLegalActs(betData?.legalAct ?? [])
      }, 100)
    }
  }, [betData, myInfo]);


  // 토너먼트 남은시간 타이머 계산
  useEffect(() => {
    if (!room || room.type !== ROOM_TYPE.TOURNAMENT) {
      return;
    }

    const startedAt = parseDatetime(room.groupData.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = room.groupData.timeStructure;

    const interval = setInterval(() => {
      let leftSec = 0;

      const elapsedForRound = calcElapsedForRound(startedAt, playTimeSeconds, restTimeSeconds);
      const playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
      const level = calcLevel(playTime, room.groupData.blindStructure);
      const blindUptime = calcBlindUpTime(playTime, room.groupData.blindStructure);
      
      if (level !== previousTournamentLevel) {
        if (addonDialogInstace !== -1) {
          closeDialog(addonDialogInstace);
          setAddonDialogInstance(-1);
        }
      }

      setPreviousTournamentLevel(level);
      
      if (blindUptime) {
        setBlindTimerText(moment.unix(blindUptime).format('블라인드 업 : mm분 ss초 남음'));
      }
      if (elapsedForRound > 0) {
        if (elapsedForRound >= playTimeSeconds) {
          // 현재 휴식 시간. 다음 라운드까지 남은 시간
          if (isRestTime) {
            leftSec = playTimeSeconds + restTimeSeconds - elapsedForRound;
          }
        } else {
          // 현재 라운드 진행 중. 다음 휴식 시간까지 남은 시간
          if (!isRestTime) {
            leftSec = playTimeSeconds - elapsedForRound;
          }
        }
      } else {
        // 토너먼트 시작 전. 시작까지 남은 시간
        leftSec = -elapsedForRound;
      }

      if (leftSec >= 0) {
        setTimerText(moment.unix(leftSec).format('mm분 ss초 남음'));
      }
      if (leftSec <= 0) {
        setRestTime(false)
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [room, isRestTime]);

  useEffect(() => {
    if(!isRebuyInAvailable && myInfo &&  myInfo?.stackSize > 0 && myInfo?.stackSize < blind.big && !myInfo.blindWait && winners.length > 0){
      
      requestSetBlindWait(roomId, true)
    }
  }, [myInfo?.stackSize, isRebuyInAvailable, blind, roomId, winners]);
  // 승자 족보 세팅
  useEffect(() => {
    if (winners.length > 0 && Object.keys(winnerCards).length > 0 && communityCards.length > 0) {
      const ceremonyCards: string[] = [];
      let ceremonyRanking = ''
      for (let winner of winners) {
        const cards = winnerCards[winner.userId];
        const solved = Hand.solve([
          ...cards.map(x => CARD_INFO[x]),
          ...communityCards.map(x => CARD_INFO[x])
        ])

        const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit));
        for (let card of targetCards) {
          if (!ceremonyCards.includes(card)) {
            ceremonyCards.push(card);
          }
        }

        if (winner.userId === myInfo?.userId || ceremonyRanking === '') {
          ceremonyRanking = solved.descr;
        }
      }

      setCeremonyCards(ceremonyCards);
      setCeremonyRanking(ceremonyRanking);
    }
  }, [myInfo?.userId, winners, winnerCards, communityCards]);


  const handleStandup = useCallback(() => {
    openDialog({
      title: '자리비움안내',
      text: '자리비움시 현재 게임이 자동으로 폴드됩니다. 정말로 진행하시겠습니까?',
      confirm: true,
      confirmText: '진행',
      onConfirm: async () => {
        playSFX(Sounds.SFX_WINDOW_OPEN, true);
        await requestStandUpRoom(roomId);
      },
      onCancel: () => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
      }
    });
  }, [roomId]);

  const handleLeave = useCallback(async () => {
    const myInfoSafe = myInfo as any;
    const isCalculateAfter = (myInfoSafe && myInfoSafe.leftBuyin);

    if (room?.type === ROOM_TYPE.RING && myInfo) {
      const { isMovedRoom, winAmount, handsCount } = myInfoSafe;

      if (isMovedRoom && winAmount && winAmount > 0) {
        const res = await requestGetHandCuttingList();
        const handCuttingList = res.list;
        const r = res.list.sort((a, b) => {
          return a.handCount - b.handCount
        })

        let lossPercentage = 0;
        let maxHands = 0;

        maxHands = r[r.length - 1].handCount;

        for (let v of handCuttingList) {
          if (handsCount <= v.handCount) {
            lossPercentage = v.lossPercentage
            break;
          }
        }

        const leave = await new Promise<boolean>((r) => {
          playSFX(Sounds.SFX_WINDOW_OPEN);
          openDialog({
            title: t("게임 종료"),
            component: <LeaveSettlementWhenMovedSpectator
              hands={handsCount}
              winAmount={winAmount}
              lossPercentage={lossPercentage}
            />,
            forceMobile: true,
            type:'custom',
            confirm: true,
            confirmText: t('정산하기'),
            cancelText: t('취소'),
            onConfirm: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(true);
            },
            onCancel: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(false);
            }
          });
        });

        if (!leave) {
          return;
        }
      }
      else if (isCalculateAfter || 
        (Number(myInfo?.stackSize) >= 0 && Number(myInfo?.handsCount) >= 0)) {
        const res = await requestGetHandCuttingList();
        const handCuttingList = res.list;
        let settle = isCalculateAfter ? myInfoSafe.leftBuyin : myInfo.stackSize;
        let buyin = isCalculateAfter ? myInfoSafe.leftBuyin : myInfo.buyin;
        let winAmount = isCalculateAfter ? myInfoSafe.winAmount : settle - buyin;
        let additionalWinAmount = myInfoSafe.additionalWinAmount;
        let handsCount = isCalculateAfter ? myInfoSafe.handsCount : myInfo.handsCount;
        let lossPercentage = 0;
        let maxHands = 0;
        
        let r = res.list.sort((a, b) => {
          return a.handCount - b.handCount
        })
        maxHands = r[r.length - 1].handCount;

        for (let v of handCuttingList) {
          if (handsCount <= v.handCount) {
            lossPercentage = v.lossPercentage
            break;
          }
        }
        
        const leave = await new Promise<boolean>((r) => {
          playSFX(Sounds.SFX_WINDOW_OPEN);
          openDialog({
            title: t("게임 종료"),
            component: <LeaveSettlement
              hands={handsCount}
              stack={settle}
              buyin={buyin}
              winAmount={winAmount}
              additionalWinAmount={additionalWinAmount}
              lossPercentage={lossPercentage}
              settle={settle}
              maxHands={maxHands}
              lack={maxHands >= handsCount}
              isStandup={false}
            />,
            forceMobile: true,
            type:'custom',
            confirm: true,
            confirmText: t('정산하기'),
            cancelText: t('취소'),
            onConfirm: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(true);
            },
            onCancel: () => {
              playSFX(Sounds.SFX_WINDOW_CLOSE)
              r(false);
            }
          });
        });

        if (!leave) {
          return;
        }
      }
    }
    const data = await requestLeaveRoom(roomId);
    
    if (data.result) {
      navigate("/main/holdem");
    }
  }, [roomId, room?.type, myInfo]);

  const handleShowOption = useCallback(() => {
    setShowOptionModal(true);
  }, []);

  const handleToggleBlindWait = useCallback(async () => {
    playSFX(Sounds.SFX_CLICK_INGAME, true)
    await requestSetBlindWait(roomId, !myInfo?.blindWait);
  }, [myInfo?.blindWait]);

  const handleExtendTimeItem = useCallback(async () => {
    playSFX(Sounds.SFX_WINDOW_OPEN);
    await requestUseExtendTimeItem(roomId);
  }, [myInfo]);

  const handleSitDown = useCallback(async (s: number) => {
    if (myInfo?.status !== ROOM_JOIN_STATUS.PLAYING) {
      setBuyInSeat(s);
    }
  }, [myInfo?.status]);

  const handleAddionalBuyIn = useCallback(async (amount: number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    setGlobalLoading(true);
    let data = await requestBuyin(roomId, amount, seat);
    if (data.result == -1) {
      openDialog({
        title: t('안내'),
        text: t('알수없는 오류로 바이인을 실패했습니다.')
      });
    } else if (data.result == -2) {
      openDialog({
        title: t('안내'),
        text: t('보유 머니가 부족하여 바이인을 실패했습니다.')
      });
    } else if (data.result == -3) {
      openDialog({
        title: t('안내'),
        text: t('이미 해당 자리에 누군가 먼저 앉아서 바이인을 실패하였습니다.')
      });
    } else if (data.result == -4) {
      openDialog({
        title: t('안내'),
        text: t('추가 바이인을 완료하였습니다.')
      });
    } else if (data.result == -5) {
      openDialog({
        title: t('안내'),
        text: t('이미 추가 바이인을 한 경우에는 한 사이클이 종료될때까지 추가 바이인을 할 수 없습니다.')
      });
    } else if (data.result == -6) {
      openDialog({
        title: t('안내'),
        text: t('바이인 최대 금액을 초과 요청하셨습니다. 금액을 줄여주세요.')
      });
    } else if (data.result == -7) {
      openDialog({
        title: t('안내'),
        text: t('최소 바이인 금액을 달성해주셔야 합니다.')
      });
    }


    requestRoomInfo(roomId);
    requestMyStatusInRoom(roomId);
    setGlobalLoading(false);
    setIsStandingUp(false);
    setAdditionalBuyinSeat(0);
    playSFX(Sounds.SFX_WINDOW_CLOSE);
  }, [buyInSeat]);

  const handleBuyIn = useCallback(async (amount: number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    setGlobalLoading(true);
    let data = await requestBuyin(roomId, amount, seat);
    if (data.result == -1) {
      openDialog({
        title: t('안내'),
        text: t('알수없는 오류로 바이인을 실패했습니다.')
      });
    } else if (data.result == -2) {
      openDialog({
        title: t('안내'),
        text: t('보유 머니가 부족하여 바이인을 실패했습니다.')
      });
    } else if (data.result == -3) {
      openDialog({
        title: t('안내'),
        text: t('이미 해당 자리에 누군가 먼저 앉아서 바이인을 실패하였습니다.')
      });
    }

    requestRoomInfo(roomId);
    requestMyStatusInRoom(roomId);
    setGlobalLoading(false);
    setIsStandingUp(false);
    playSFX(Sounds.SFX_WINDOW_CLOSE);
  }, [buyInSeat]);

  useEffect(()=>{
    if(showHistory) {
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showHistory]);

  useEffect(()=>{
    if(showMenu) {
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showMenu]);

  useEffect(() => {
    if(showHandDesc){
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showHandDesc]);

  useEffect(() => {
    if(showEmoticonSelector){
      playSFX(Sounds.SFX_WINDOW_OPEN);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE);
    }
  }, [showEmoticonSelector]);

  const handleRejoinGame = useCallback(async () => {
    if (!isRebuyInAvailable || !myInfo) {
      return;
    }

    if (room?.type === ROOM_TYPE.RING) {
      setBuyInSeat(myInfo.seat);
    } else if (room?.type === ROOM_TYPE.TOURNAMENT) {
      // 0원되면 그냥 쫒겨남
      // openDialog({
      //   title: t('바이인 안내'),
      //   text: `리바이인 시 ${room.groupData.rebuyinChip.toLocaleString()} 을 얻고, ${room.groupData.buyinPrice} 를 지출하게 됩니다.<br/>현재 머니 : ${myProfileInfo?.money}`
      //   confirm: true,
      //   confirmText: '리바인',
      //   cancelText: '취소',
      //   onConfirm: async () => {
      //     await requestRebuyinTournament(room.groupId);
      //   }
      // });
    }
  }, [room, myInfo, isRebuyInAvailable, myProfileInfo]);

  const handleCancelMoveReserve = useCallback(async () => {
    playSFX(Sounds.SFX_WINDOW_OPEN);
    await requestMoveRingCancel();
    setStatusAwaitMoveRoom(0);
  }, [roomId]);

  const handleTryBet = useCallback(async (type: BET_TYPE, betAmount: number) => {
    playSFX(Sounds.SFX_CLICK_INGAME);
    await requestTryBet(roomId, type, betAmount);
    setShowAutoActionForRequest(false)
    setBetData(null);
  }, [roomId]);

  const handleTryBet2 = useCallback(async (type: BET_TYPE, betAmount: number) => {
    playSFX(Sounds.SFX_CLICK_INGAME);

    if (innerLegalActs.includes("check") && type === BET_TYPE.FOLD) {
      const c = openDialog({
        title: t('안내'),
        text: t('체크 하실 수 있습니다.<br/>폴드 대신 체크 하시겠습니까?'),
        confirm: true,
        confirmText: '체크',
        cancelText: '폴드',
        onConfirm: async () => {
          playSFX(Sounds.SFX_WINDOW_OPEN, true);
          type = BET_TYPE.CHECK;

          await requestTryBet(roomId, type, betAmount);
          setShowAutoActionForRequest(false)
          setBetData(null);
        },
        onCancel: async () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true);

          await requestTryBet(roomId, type, betAmount);
          setShowAutoActionForRequest(false)
          setBetData(null);
        }
      });

      setOpendDialogId(c);
    }
    else {
      await requestTryBet(roomId, type, betAmount);
      setShowAutoActionForRequest(false)
      setBetData(null);
    }
  }, [roomId, innerLegalActs]);

  const handleOpenProfile = useCallback((userId: number) => {
    setProfileUserId(userId);
  }, []);

  const handlePlayerCallbackReset = () => {
    
  };

  const handleMeOpenCard = useCallback(async (index:number) => {
    playSFX(Sounds.SFX_WINDOW_OPEN, true);
    await requestUseOpenCard(roomId, index);
  }, [roomId]);

  const handleSendEmoticon = useCallback(async (emoticonId?: number) => {
    if (emoticonId) {
      await requestRequestEmoji(roomId, emoticonId);
    }
    setShowEmotionHotKey(false)
    setShowEmoticonSelector(false);
  }, [roomId]);

  const isMenuOpened = useCallback(() => {
    return showMenu;
  }, [showMenu]);

  if (!room) {
    return <></>;
  }
  const showActionButton = (showActions &&
    !(
      (
        autoAction === 'check/fold' && (
          betData!.legalAct.includes('check') || betData!.legalAct.includes('fold')
        )
      ) ||
      (
        autoAction === 'autocall' && (
          betData!.legalAct.includes('call'))
      )
    )
  )
  const showWaitButton = (
    (myInfo !== undefined) && !myInfo.waitGame && (
      playersBetting.length > 0 ?
        (
          room.currentRound !== 'pre-flop' ? (
            (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet === 0) ||
            (playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet)
          ) : (
            (
              playersAct[myInfo.userId] === BET_TYPE.BB ||
              players.find(_ => _?.userId === myInfo.userId)?.lastAction === BET_TYPE.BB
            ) ||
            playersBetting.sort((a, b) => b.bet - a.bet)[0].bet > myInfo.bet
          )
        ) : true
    ) && (showAutoActionForRequest) && (showAutoAction) && (room.roomStatus === ROOM_STATUS.INGAME) &&
    (myInfo.status !== ROOM_JOIN_STATUS.FOLD) && (!myInfo.blindWait) && (myInfo.stackSize > 0)
  )
  
  const StatusBoardComponent = <StatusBoard
    room={room}
    roomType={room.type}
    SB={blind.small}
    BB={blind.big}
    ante={blind.ante}
    rakeType={room.groupData.rakeType}
    rake={room.groupData.rake}
    myRank={myRank}
    totalMember={totalMember}
    straddle={room.groupData.isStraddle}
    timerText={timerText}
    blindUpTimerText={blindTimerText}
  />;

  return <>
    {
      orientation === 'portrait' && (
        <ParticleBackground tableStyle={tableStyle}/>
      )
    }
    <Wrapper>
      <Header>
        <div className={"left"}>
          <Popup
            ref={refMenu}
            trigger={
              <MenuButton>
                <img alt='menu' src='/images/ic_menu.png'/>
                {ChatCount ? (
                  <span>{ChatCount}</span>
                ) : <></>}
              </MenuButton>
            }
            position="bottom left"
            closeOnDocumentClick
            onOpen={()=>{playSFX(Sounds.SFX_CLICK_INGAME, true)}}
          >
            <GameMenu
              isTournament={room?.type === ROOM_TYPE.TOURNAMENT}
              thisRef={refMenu}
              onClose={() => setShowMenu(false)}
              onRoomLeave={handleLeave}
              onClickCS={()=>setShowCustomerCenter(true)}
              onClickInstantSeat={()=> {
                if (players) {
                  const nonUndefinedIndexes = _.filter(_.range(players.length), (index:number) => _.isUndefined(players[index]));
                  const randomIndex = _.sample(nonUndefinedIndexes);

                  if (nonUndefinedIndexes.length === 0) {
                    openDialog({
                      title: t('안내'),
                      text: t('방에 모든 자리에 플레이어가 있습니다.')
                    });
                  }
                  setBuyInSeat(randomIndex);
                } 
              }}
              onClickHelp={()=>{
                setShowHandDesc(true)
              }}
              onClickStandup={()=>{
                if (myInfo) {
                  handleStandup();
                }
              }}
              onClickAddionalBuyin={()=>{
                setAdditionalBuyinSeat(1);
              }}
              onClickOtherRingStatus={()=>{
                setShowOtherRingStatus(true);
              }}
              onClickGameStatusBoard={()=>{
                setGameStatusBoard(true);
              }}
              ChatCount={ChatCount}
              SeatedMyInfo={myInfo}
            />
          </Popup>
          {
            statusAwaitMoveRoom === 1 && (
            <div className={"sdfsfx"} onClick={handleCancelMoveReserve}>
              <span>방 이동이 예약됨 (클릭시 취소)</span>
            </div>  
            )
          }
        </div>
        <div className="right">
          {
            <HistoryButton onClick={()=> {
              const isMuteded = setting.backgroundMusic.muted && setting.effectSound.muted;

              setSetting({
                ...setting,
                effectSound: {
                  ...setting.effectSound,
                  muted: !isMuteded
                },
                backgroundMusic: {
                  ...setting.backgroundMusic,
                  muted: !isMuteded
                }
              });
            }}>
              <img alt='mute' src={`/images/Icon/mute${setting.backgroundMusic.muted && setting.effectSound.muted ? 'on' : 'off'}.png`}/>
            </HistoryButton>
          }
          {
            room && room.handNumber > 0 && <HistoryButton onClick={()=>setShowHistory(true)}>
              <img alt='history' src='/images/Icon/History.png'/>
            </HistoryButton>
          }
          
        </div>
      </Header>
      <StatusWrap className={`status-wrap`}>
        {StatusBoardComponent}
      </StatusWrap> 
      <LogoWrap className={`logo-wrap ${room?.type === ROOM_TYPE.TOURNAMENT ? 'isTournament': ''}`}>
        <img src="/images/background_deep2.png" alt="logo"/>
      </LogoWrap>
      <GameTable>
        {
          tableStyle > 0 && <img
            className="game-table"
            src={`/images/game/table_${orientation}_type_${tableStyle}.png`}
          />
        }
        <DealerButton className="dealer-button"/>

        <div className="table-hole"/>

        {
          players.map((p, i) => {
            const userId = p?.userId ?? -1;
            const isMe = userId === myInfo?.userId;
            const myInfoSafe = myInfo as any;
            const disabled = !p && !!myInfoSafe && myInfoSafe.type !== "spectator";

            const isBetNow = betData?.userId == userId;
            const winningRate = calculator.winningRates.find(x => x.userId === userId);

            const cards = [];
            let isOpendFlag = false;
            let meOpendCards:any = [];

            const isNoneWincards = Object.keys(winnerCards).length === 0;

            if (isMe && Array.isArray(myCards)) {
              cards.push(...myCards);

              if (opendCards[userId]) {
                meOpendCards = opendCards[userId];
              }
            } else if (winnerCards[userId]) {
              cards.push(...winnerCards[userId]);
            } else if (opendCards[userId]) {
              isOpendFlag = true;
              cards.push(...opendCards[userId]);
            }

            const isChipLeader = ((chipLeader?.stackSize ?? 0) !== 0) && chipLeader?.userId === userId;
            let blindPos: 'SB' | 'BB' | 'STR' | null = null
            const isAllIn = players.find(x => x?.userId === userId && x.status === ROOM_JOIN_STATUS.PLAYING)?.stackSize === 0

            if(room && p){
              if(room.bbPosition === p.seat){
                blindPos = 'BB'
              }
              if(room.sbPosition === p.seat){
                blindPos = 'SB'
              }
              if(room.groupData && room.groupData.isStraddle && room.strPosition === p.seat){
                blindPos = 'STR'
              }
            }
            return <Player
              tableStyle={tableStyle}
              isAllIn={isAllIn}
              blindPos={blindPos}
              isChipLeader={showChipLeader && isChipLeader}
              key={i}
              idx={i}
              player={p}
              BB={blind.big}
              act={playersAct[userId]}
              isUseExtendTimer={isUseExtendTimer}
              me={isMe}
              betNow={isBetNow}
              disabled={disabled}
              winners={winners}
              rate={winningRate?.rate}
              communityCards={communityCards}
              rabbitHuntingList={rabbitHuntingList}
              cards={cards}
              isOpendFlag={isOpendFlag}
              meOpendCards={meOpendCards}
              onClickExtendTimeItem={handleExtendTimeItem}
              onClickSeat={handleSitDown}
              onClickProfile={handleOpenProfile}
              onClickEmoticon={() => setShowEmoticonSelector(true)}
              onClickMeOpenCard={handleMeOpenCard}
              maxTableMember={maxTableMember}
              isStandingUp={isMe && isStandingUp}
              isGameWinExist={isGameWinExist}
              isNoneWincards={isNoneWincards}
            />;
          })
        }
        {
          Array.from({length: maxTableMember}).map((v, i) => {
            const p = players[i];
            const userId = p?.userId ?? -1;
            let amount = 0;
            let isAllIn = false;
            if (p && playersBetting) {
              const playerBetting = playersBetting.find(q => q.id == userId);
              if (playerBetting) {
                isAllIn = playerBetting.bet > 0 && playerBetting.stackSize === 0
                amount = playerBetting.bet;
              }
            }
            return <PlayerPot seat={i} key={i} userId={userId} bb={blind.big} amount={amount} isAllIn={isAllIn}/>;
          })
        }

        <FieldPots room={room} total={totalPotSize} pots={pots} BB={blind.big}>
        </FieldPots>
        <RabbitHuntCardWrapper 
          data-length={getRabbitHuntHideCardsLength()} 
          className={`rabbit-hunt-button`}
          onClick={async ()=> {
            const r = await requestOpenRabbitHuntingCard(roomId, rabbitHuntingLastHandNumber);

            if (r.result === 1) {
              setRabbitHuntingList(r.cards);

              Array.from(document.getElementsByClassName("rabbit-hunt-button")).forEach(element => {
                if (element.classList.contains("active")) {
                  element.classList.remove("active");
                }
              });
            }
          }}
        >
          <img src="/images/nes/cardView.png" alt="card-viewer"/>
          <span className='sdfnsdf'>카드보기</span>
          <div>
            <img src="/images/nes/won.png" alt="card-viewer"/>
            <span>100</span>
          </div>
        </RabbitHuntCardWrapper>
        <CommunityCards 
          className="community-cards-wrapper" 
          data-cards={innerCommunityCards.length}
          noAnimation={noRoundAnim}
        >
          <div className="rabbitLine"/>
          {
            Array.from({length: 5}).map((_, i) => {
              const showCard = innerCommunityCards[i] >= 0;

              if (showCard) {
                return <PokerCard
                  tableStyle={tableStyle}
                  isCommunityCard={true}
                  isRabbitHunting={false}
                  key={i}
                  className="community-card"
                  data-open={showCard}
                  card={innerCommunityCards[i]}
                  flip
                  delay={noRoundAnim ? -1 : 1000}
                  startRemoveAnimation={startRemoveCommunityCards ? (5 - i - 1) * 200 : undefined}
                />;
              } 
              else {
                const isShowCardRabbitHunt = !!(rabbitHuntingList[i] || rabbitHuntingList[i] === 0);

                if (isShowCardRabbitHunt) {
                  return <PokerCard
                    tableStyle={tableStyle}
                    isCommunityCard={false}
                    isRabbitHunting={true}
                    key={i}
                    className="rabbitHunt-card"
                    data-open={isShowCardRabbitHunt}
                    card={rabbitHuntingList[i]}
                    {...(rabbitHuntingList[i] !== -1 && { flip: true })}
                    startRemoveAnimation={startRemoveCommunityCards ? (5 - i - 1) * 200 : undefined}
                  />;
                } 
                else {
                  return <div key={i}/>;
                }
              }
            })
          }
          {
            ceremonyRanking && (
              <WinnerHandRanking>
                <div>{ceremonyRanking}</div>
              </WinnerHandRanking>
            )
          }

        </CommunityCards>
        <CoinMoveWrapper className="coin-move-wrapper"/>
        {
          ceremonyRanking && (
            <CeremonyDimmer/>
          )
        }
        {
          ((orientation === 'portrait' && !showEmoticonSelector) || orientation === 'landscape') && (
            <InGameButtonWrapper className="ingame-button-wrapper">
              <ActionButtons
                bb={blind.big}
                show={showActionButton}
                onClickTryBet={handleTryBet2}
                myInfo={myInfo!}
                room={room}
                legalActs={innerLegalActs}
                totalPot={totalPotSize}
              />
              <WaitButtonWrapper show={!showActionButton && showWaitButton}>
                <InGameButton mode={'prebet'} checked={autoCheckFold} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCheckFold) {
                    setAutoCheckFold(false)
                  } else {
                    setAutoCheckFold(true)
                    setAutoCall(false)
                    setAutoCallAny(false)
                  }
                }}>
                  예약 체크/폴드
                </InGameButton>
                <InGameButton mode={'prebet'} checked={autoCall} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCall) {
                    setAutoCall(false)
                  } else {
                    setAutoCall(true)
                    setLastBet(room?.currentBet)
                    setAutoCheckFold(false)
                    setAutoCallAny(false)
                  }
                }}>예약 콜
                </InGameButton>
                <div className='dummy'></div>
                <div className='dummy2'></div>
                {/*
                <InGameButton mode={'prebet'} checked={autoCallAny} onChecked={() => {
                  playSFX(Sounds.SFX_CLICK_INGAME, true)
                  if (autoCallAny) {
                    setAutoCallAny(false)
                  } else {
                    setAutoCallAny(true)
                    setAutoCall(false)
                    setAutoCheckFold(false)
                  }
                }}>CALL ANY
                </InGameButton>
                */}
              </WaitButtonWrapper>

              {
                showJoinButtonRing && (
                  <WaitButtonWrapper show={showJoinButtonRing}>
                    {
                      isRebuyInAvailable ? (
                        <InGameButton mode={'prebet'} onClick={handleRejoinGame}>
                          {t('게임 참여하기')}
                        </InGameButton>
                      ) : (
                        <InGameButton mode={'prebet'} checked={myInfo?.blindWait} onChecked={handleToggleBlindWait}>
                          {t('블라인드 대기')}
                        </InGameButton>
                      )
                    }
                  </WaitButtonWrapper>
                )
              }
              {
                showJoinButtonTournament && (
                  <JoinButtonWrapper>
                    {
                      // isRetired ? (
                      //   <InGameButton onClick={handleRejoinGame}>
                      //     게임 참여하기
                      //   </InGameButton>
                      // ) : null
                    }
                  </JoinButtonWrapper>
                )
              }
            </InGameButtonWrapper>
          )
        }
      </GameTable>
      <EmoticonHotKeyWrapper show={showEmotionHotKey && !showJoinButtonRing && myInfo && myInfo.type === "players"}>
        {[13, 17, 2].map((x, i) => {
          return <img key={i} className='item' src={`/images/emoji/${x}.svg`} onClick={()=>handleSendEmoticon(x)}/>
        })}
      </EmoticonHotKeyWrapper>
      
      {
        room.type === ROOM_TYPE.RING && (
          <ModalContainer
            show={buyInSeat !== -1} onBackdropClick={() => setBuyInSeat(-1)}>
            <BuyInModal
              onClose={() => {
                playSFX(Sounds.SFX_WINDOW_CLOSE)
                setBuyInSeat(-1)
              }}
              show={buyInSeat !== -1}
              bigBlind={blind.big}
              minBuyIn={room.groupData.minBuyin}
              maxBuyIn={room.groupData.maxBuyin}
              onClickBuyIn={handleBuyIn}
            />
          </ModalContainer>
        )
      }
      {
        isRestTime && (
          <RestTimePopup>{'휴식시간입니다.'}</RestTimePopup>
        )
      }
      <LeftDrawer
        opened={gameStatusBoard}
        onOpen={async () => {
          const r = await requestRoomInfo(roomId);
          setGameStatusBoard(true);
          setInterval
        }}
        onClose={() => {
          setGameStatusBoard(false)
        }}
      >
        <GameStatusBoard 
          room={roomThirdParty}
          onClose={()=> {
            setGameStatusBoard(false)
          }}
        />
      </LeftDrawer>
      <RightDrawer
        opened={showHistory}
        onOpen={() => setShowHistory(true)}
        onClose={() => setShowHistory(false)}
      >
        <GameHistory
          groupId={room.groupId}
          roomId={roomId}
          handNumber={room?.handNumber}
          currentRound={room?.currentRound}
          maxTableMember={room?.groupData.maxTableMember}
          onClose={() => setShowHistory(false)}
          isOpen={showHistory}
        />
      </RightDrawer>
      <RightDrawer2
        opened={showOtherRingStatus}
        onOpen={() => setShowOtherRingStatus(true)}
        onClose={() => setShowOtherRingStatus(false)}
      >
        <OtherRingStatus
          roomId={roomId}
          groupId={room.groupId}
          groupData={room.groupData}
          onClose={() => setShowOtherRingStatus(false)}
          onMoveReserved={()=> {
            setStatusAwaitMoveRoom(1);
          }}
          isOpen={showOtherRingStatus}
          myInfo={myInfo}
        />
      </RightDrawer2>
      <ModalContainer
        show={showCustomerCenter} onBackdropClick={() => setShowCustomerCenter(false)}>
        { showCustomerCenter ? (
          <CustomerCenterInGame 
            onClose={()=> setShowCustomerCenter(false)}
          />
        ) : <></>}
      </ModalContainer>
      <ModalContainer show={showHandDesc} onBackdropClick={() => setShowHandDesc(false)}>
        <HandDescModal
          onClose={()=>setShowHandDesc(false)}
        />
      </ModalContainer>
      <ModalContainer show={additionalBuyinSeat === 1} onBackdropClick={() => setAdditionalBuyinSeat(0)}>
        <BuyInModal
          onClose={() => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
            setAdditionalBuyinSeat(-1)
          }}
          type={'additional'}
          show={additionalBuyinSeat === 1}
          bigBlind={blind.big}
          minBuyIn={myInfo && myInfo.stackSize < room.groupData.minBuyin ? room.groupData.minBuyin - myInfo.stackSize : 1}
          maxBuyIn={myInfo ? room.groupData.maxBuyin - myInfo.stackSize : 0}
          onClickBuyIn={handleAddionalBuyIn}
        />
      </ModalContainer>
      <ModalContainer show={profileUserId !== -1} onBackdropClick={() => setProfileUserId(-1)}>
        <ProfileModal groupId={room.groupId} userId={profileUserId} onClose={() => setProfileUserId(-1)}/>
      </ModalContainer>
      <ModalContainer show={showOptionModal} onBackdropClick={() => setShowOptionModal(false)}>
        <SettingModal onClose={() => setShowOptionModal(false)}/>
      </ModalContainer>
      <ModalContainer show={showEmoticonSelector} noDim onBackdropClick={() => setShowEmoticonSelector(false)}>
        <EmoticonSelector onClose={handleSendEmoticon}/>
      </ModalContainer>
    </Wrapper>
  </>;
}

function InitLayout() {
  const [hasCacheGameData, setHasCacheGameData] = useRecoilState(hasCacheGameDataState);
  const [showLoading, setShowLoading] = useState<boolean>(hasCacheGameData > 0 ? false : true);
  const orientation = useScreenOrientation();
  const [tableStyle, setTableStyle] = useState<number>(-1);
  const [setting] = useRecoilState(gameOptionState);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const roomId = useMemo<number>(() => {
    return Number(searchParams.get("id")!);
  }, [searchParams]);

  useEffect(() => {
    requestCurrentRoom(roomId).then((res) => {
      if (!res?.isExist) {
        navigate('/');
      }
      else {
        const room = res.roomInfo;
        const blind = {
          small: 0,
          big: 0,
          ante: 0,
        };

        if (room?.type === ROOM_TYPE.RING) {
          blind.small = room?.groupData.blind[0];
          blind.big = room?.groupData.blind[1];
          if (room?.groupData.isAnte) {
            blind.ante = room?.groupData.ante;
          }
        } 
        else if (room?.type === ROOM_TYPE.TOURNAMENT) {
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          const playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
          const level = calcLevel(playTime, room.groupData.blindStructure);
          
          blind.small = room.groupData.blindStructure[level][0];
          blind.big = room.groupData.blindStructure[level][1];
          blind.ante = room.groupData.blindStructure[level][2];
        }

        let tabStyle = 0;

        if (blind.big > 0) {
          if (blind.big <= 1000 ) {
            tabStyle = setting.tableStyle_LV1 ?? 1;
          } else if (blind.big <= 10000) {
            tabStyle = setting.tableStyle_LV2 ?? 2;
          } else {
            tabStyle = setting.tableStyle_LV3 ?? 3;
          }
        }

        if (tabStyle > 0) {
          setTableStyle(tabStyle);

          if (hasCacheGameData === tabStyle) {
            setShowLoading(false);
          }
        }
      }
    })
  }, []);
  
  return showLoading ? <LoadingGameResource 
    orientation={orientation} 
    tableStyle={tableStyle} 
    onDownloadDone={()=>{
      setShowLoading(false);
      setHasCacheGameData(tableStyle);
    }}
  /> : <GameLayout />;
}

export default InitLayout;