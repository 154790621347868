import {useEffect, useMemo, useState} from "react";
import {parseDatetime} from "../constants/moment";
import moment from "moment/moment";
import {calcPlayTime} from "../utils/tournament";

function useTournamentTimer(
  {
    data,
    format = "mm:ss"
  }: {
    data?: any;
    format?: string
  }
) {
  const [timerText, setTimerText] = useState<string>('');
  const [isTournamentStarted, setTournamentStarted] = useState<boolean>(false);
  const [isClosed, setClosed] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    } else if (isClosed) {
      return;
    }

    function update() {
      const startedAt = parseDatetime(data.startedAt);
      const isStarted = moment().utc().isSameOrAfter(startedAt);

      let leftSec = 0;
      if (isStarted) {
        if (!isTournamentStarted) {
          setTournamentStarted(true);
        }

        const {
          playTimeSeconds,
          restTimeSeconds
        } = data.timeStructure;
        const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);

        let closeTime = 0;
        for(let i = 0; i < data.availableRegisterLevel ; ++i) {
          closeTime += data.blindStructure[i][3]
        }

        leftSec = closeTime - playTime;
      } else {
        leftSec = startedAt.unix() - moment().unix();
      }

      if (leftSec >= 0) {
        const duration = moment.duration(leftSec, 'seconds');
        const mins = String(Math.floor(duration.asMinutes())).padStart(2, '0');
        const secs = String(duration.seconds()).padStart(2, '0');

        setTimerText(`${mins}:${secs}`);
      } else {
        setClosed(true);
      }
    }

    update();
    const interval = setInterval(() => {
      update();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [data, format, isTournamentStarted, isClosed]);

  return {
    isTournamentStarted,
    timerText,
    isClosed
  };
}

export default useTournamentTimer;