import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {
  requestGetDWArticle,
  requestGetTransactionList,
  requestMyInfo,
  requestRequestUserTransaction,
  requestRequestUserVerify, useSocketIsConnect,
  requestRemoveAllTransactionList
} from "../api";
import {startLoading, stopLoading} from "../components/Loading";
import PopupWindow from "../components/PopupWindow";
import AccountVirtualPopup from "../components/AccountVirtualPopup";

import {MyInfo, TransactionStatus, TX_TYPE, UserTransactionData, UserVerified, VAccInfo} from "../dataset";
import moment from "moment";
import useDialog from "../hooks/useDialog";
import {playSFX, Sounds} from "../utils/sound";
import {sha256} from "js-sha256";
import {setAckListener as setNeedUiRefreshListener} from "../api/from_server_ping_needUIRefresh";
import {useNavigate} from "react-router-dom";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const TradeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    background: white;
    overflow-y: scroll;
  }

  > .tab-wrapper {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      margin-top: 40px;
      max-width: 272px;
      justify-content: space-between;
    }

    > .tab {
      cursor: pointer;
      padding: 14px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-bottom: 2px solid #25262D;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        border-radius: 25px;
        border: none;
        color: #FFF;
        text-align: center;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
        width: 116px;
        height: 30px;
        flex: unset;
        color: #181818;
      }
    }

    > .selected {
      border-bottom: 2px solid #FFF;
      @media ${MEDIA_DESKTOP} {
        color: #FFF;
        border-bottom: none;
        border-radius: 25px;
        border-top: 1px solid rgba(255, 255, 255, 0.50);
        background: #0896FC;
        box-shadow: 0px 2px 6px 0px rgba(8, 150, 252, 0.50);
      }
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      overflow-y: unset;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`
export const HorizontalBar = styled.div`
  width: 100%;
  height: 12px;
  background: #25262D;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }
`
const HistoryWrapper = styled.div`
  width: 100%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media ${MEDIA_DESKTOP} {
    background: #F5F5F5;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 0;
    flex-grow: 1;
  }

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    @media ${MEDIA_DESKTOP} {
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    @media ${MEDIA_DESKTOP} {
      max-width: 470px;
      gap: 0px;
      margin-top: 16px;
    }

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;

      @media ${MEDIA_DESKTOP} {
        padding: 12px 20px;
        align-items: center;
        &:nth-child(odd) {
          border-top: 1px solid #DDD;
          border-bottom: 1px solid #DDD;
          background: #FFF;
        }
      }

      > .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
      }

      .money {
        color: #FFF;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      .status {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-shrink: 0;
        color: #E31D1D;
        text-align: right;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Point-Red, #E31D1D);
          text-align: right;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      .createdAt {
        color: #B4B4B4;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
        @media ${MEDIA_DESKTOP} {
          color: #B4B4B4;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
    }
  }
`
const EmptyWrapper = styled.div`
  margin-top: 48px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  @media ${MEDIA_DESKTOP} {
    color: var(--Black, #181818);
  }
`
const DepositWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media ${MEDIA_DESKTOP} {
    flex-grow: 1;
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 24px 16px;
    gap: 24px;

    > .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      @media ${MEDIA_DESKTOP} {
        max-width: 470px;
      }

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      > .content {
        width: 100%;
        padding: 10px 12px;
        color: #DADADA;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        border-radius: 8px;
        background: #262838;
        @media ${MEDIA_DESKTOP} {
          border-radius: 8px;
          background: #F6F6F6;
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          padding: 24px;
        }
      }

      > .money-input-wrapper {
        width: 100%;
        @media ${MEDIA_DESKTOP} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        > .input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 6px;
          border: 1px solid #25262D;
          height: 36px;
          padding-left: 10px;
          padding-right: 12px;
          @media ${MEDIA_DESKTOP} {
            border-radius: 4px;
            border: 1px solid rgba(24, 24, 24, 0.30);
            height: 44px;
          }

          > .point {
            width: 16px;
            height: 16px;
            object-fit: contain;
            flex-shrink: 0;
            @media ${MEDIA_DESKTOP} {
              width: 24px;
              height: 24px;
            }
          }

          > input {
            flex-grow: 1;
            outline: none;
            background: none;
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
            border: none;
            text-align: right;
            @media ${MEDIA_DESKTOP} {
              color: var(--Black, #181818);
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%; /* 16px */
            }
          }
        }

        > .money-grid {
          margin-top: 8px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 8px;
          @media ${MEDIA_DESKTOP} {
            max-width: 426px;
          }

          > .money {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 36px;
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.5px;
            border-radius: 4px;
            background: #25262D;
            @media ${MEDIA_DESKTOP} {
              border-radius: 28px;
              border: 1px solid var(--Black, #181818);
              background: none;
              color: var(--Black, #181818);
              text-align: center;
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.5px;
            }
          }
        }
      }
    }

    > .virtual-account {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      > .content {
        width: 100%;
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 8px;
        background: #25262D;
        @media ${MEDIA_DESKTOP} {
          border-radius: 8px;
          background: #F6F6F6;
          max-width: 470px;
        }

        > .time {
          color: var(--Point-Red, #E31D1D);
          text-align: right;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Point-Red, #E31D1D);
            text-align: right;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }

        > .description {
          color: #FFF;
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            text-align: center;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.5px;
          }
        }
      }

      > .button {

      }
    }


    .button {
      cursor: pointer;
      width: 100%;
      height: 36px;
      border-radius: 6px;
      background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        max-width: 280px;
        height: 44px;
        border-radius: 22px;
        background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
      }
    }
    .button2 {
      background: var(--Gradient-Mint, linear-gradient(180deg, #167aaf 0%, #10588d 100%));
    }

    .button.selected {
      border-radius: 6px;
      border: 1px solid var(--Point-Red, #E31D1D);
      background: rgba(227, 29, 29, 0.50);
    }

    .button.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`

const DelHistoryButtonWrapper = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  background: rgb(37, 38, 45);
  width: 100%;
  padding-bottom: 10px;
  padding-right: 6px;
  @media ${MEDIA_DESKTOP} {
    justify-content: center;
    -webkit-justify-content: center;
    background: transparent;
  }

  .button {
    cursor: pointer;
    width: 110px;
    height: 30px;
    border-radius: 6px;
    background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
  }
`;
interface DepositProps {
  showCustomerCenter: () => void,
  info: string
}

const Deposit = ({info, showCustomerCenter}: DepositProps) => {
  const {openDialog} = useDialog();
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null)
  const timerRef = useRef<HTMLDivElement>(null)
  const [virtualAccount, setVirtualAccount] = useState<null | {
    vacc: VAccInfo,
    addedAt: string,
    amount: number
  }>(null);
  const [prevUserVerified, setPrevUserVerified] = useState<UserVerified | null>(null)
  const [latestTransaction, setLatestTransaction] = useState<UserTransactionData | null>(null)
  const [refresh, setRefresh] = useState<number>(new Date().getTime())
  const [transactionList, setTransactionList] = useState<UserTransactionData[]>([]);
  const [showVaccPopupButton, setShowVaccPopupButton] = useState<boolean>(false);
  const [windowInstance, setWindowInstance] = useState<any>(null);
  const [accountView, setAccountView] = useState<boolean>(false);
  const [popupSize] = useState({width: window.screen.availWidth, height: window.screen.availHeight});
  const orientation = useScreenOrientation();
  
  const showDepositSuccessRef = useRef<UserTransactionData | null>(null)
  useEffect(() => {
    requestMyInfo().then(res => {
      setMyInfo(res.info)
      if (prevUserVerified === null || res.info.verified === UserVerified.REQUESTED) {
        setPrevUserVerified(res.info.verified)
      }
    })
  }, [refresh, prevUserVerified]);

  useEffect(() => {
    setNeedUiRefreshListener(() => {
      requestMyInfo().then(res => {
        setMyInfo(res.info);
        setRefresh(new Date().getTime())
      })
    })

    return () => {
      setNeedUiRefreshListener(null);
    }
  }, [])

  useEffect(() => {
    if (myInfo) {
      if (prevUserVerified === UserVerified.REQUESTED) {
        if (myInfo.verified === UserVerified.ACCEPTED) {
          setPrevUserVerified(myInfo.verified)
          openDialog({
            title: '승인 완료',
            text: '계정 승인이 되었습니다.',
            disableBackdropClick: true
          })
        }
      }
    }
  }, [prevUserVerified, myInfo?.verified]);

  const handleRequestVerify = () => {
    playSFX(Sounds.SFX_WINDOW_OPEN, true)
    requestRequestUserVerify().then(res => {
      setRefresh(new Date().getTime())
      if (res.result) {
        openDialog({
          title: '계정 인증 신청',
          component: <div>
            계정 인증 신청이 완료되었습니다.<br/>
            계정 인증 진행은 고객센터를 통해 가능합니다.
          </div>,
          disableBackdropClick: true,
          confirm: true,
          confirmText: '고객센터 이동',
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_OPEN, true);
            showCustomerCenter()
          },
          onCancel: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true);
          }
        })
      } else {
        openDialog({
          title: '계정 인증 신청',
          text: '계정 인증 신청이 실패하였습니다.',
          disableBackdropClick: true
        })
      }
    })
  }
  useEffect(() => {
    if (showDepositSuccessRef.current) {
      openDialog({
        title: '충전완료',
        text: '충전금액 ' + showDepositSuccessRef.current.amount.toLocaleString() + '원이<br/> 정상적으로 충전되었습니다.',
        disableBackdropClick: true,
      })
    }
  }, [showDepositSuccessRef.current?.txId]);
  useEffect(() => {
    requestGetTransactionList().then(res => {
      setTransactionList(res.list)

      const depositList = res.list.filter(x => x.type === TX_TYPE.deposit)
      
      console.log('depositList');
      console.log(depositList);
      
      if (depositList.length > 0) {
        const latestItem = depositList[0];

        if (latestTransaction !== null && latestTransaction.txId === latestItem.txId) {
          if (latestTransaction.status === TransactionStatus.pending && latestItem.status === TransactionStatus.accepted) {
            showDepositSuccessRef.current = latestItem;
          }
        }

        setLatestTransaction(latestItem)
        if (latestItem.status === TransactionStatus.pending) {
          setShowVaccPopupButton(true);

          if (latestItem.vacc && (latestItem.vacc as any).error === undefined) {
            setVirtualAccount({
              vacc: latestItem.vacc,
              amount: latestItem.amount,
              addedAt: latestItem.addedAt
            })
          }
          else {
            setVirtualAccount({
              vacc: {bank: '발급대기중..', 'vacc': '발급대기중..', 'accountName': '발급대기중..'},
              amount: -1,
              addedAt: latestItem.addedAt
            })
          }
        } else {
          setVirtualAccount(null);
          setShowVaccPopupButton(false);
          closePopup();
        }
      }
    })
  }, [refresh, latestTransaction?.txId]);

  const handleRemoveAllHistory = () => {
    requestRemoveAllTransactionList(TX_TYPE.deposit).then(res => {
      if (res.success) {
        const nesList = transactionList.filter(x => x.status === TransactionStatus.pending);
        setTransactionList(nesList);
      }
    });
  };

  const handleCharge = () => {
    if (transactionList && transactionList.length > 0) {
      const pendingTransaction = transactionList.filter(x => x.status === TransactionStatus.pending || x.status === TransactionStatus.hold);

      if (pendingTransaction.length> 0) {
        openDialog({
          title: '충전신청',
          text: '이미 충전신청이나 환전신청이 진행중입니다.<br/>모두 완료된 상태에서 신청 부탁드리겠습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true)
          }
        })
        return
      }
    }
    const money = document.getElementById('money') as HTMLInputElement;
    const moneyValue = parseInt(money.value.replace(/,/g, ''))
    if (moneyValue < 0) {
      openDialog({
        title: '충전신청',
        text: '충전금액을 확인해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (moneyValue < 10000) {
      openDialog({
        title: '충전신청',
        text: '최소 충전금액은 1만원입니다.',
        disableBackdropClick: true
      })
      return
    }
    if (moneyValue % 10000 !== 0) {
      openDialog({
        title: '충전신청',
        text: '충전금액은 1만원 단위로 가능합니다.',
        disableBackdropClick: true
      })
      return
    }
    
    const loading = startLoading()
    requestRequestUserTransaction(TX_TYPE.deposit, moneyValue, '').then(res => {
      if (res.result) {
        money.value = '';
        
        openDialog({
          title: '충전신청',
          text: '충전신청이 완료되었습니다.',
          disableBackdropClick: true
        })
        setRefresh(new Date().getTime())
      } else {
        openDialog({
          title: '충전신청',
          component: <div>충전신청이 실패하였습니다.<br/>{res.msg}</div>,
          disableBackdropClick: true
        })
      }
    }).finally(() => {
      stopLoading(loading)
    })
  }

  const handleSelectMoney = (money: number) => {
    playSFX(Sounds.SFX_CLICK, true)
    const input = document.getElementById('money') as HTMLInputElement;
    const innerMoney = parseInt(input.value.replace(/,/g, ''))
    if (money === 0) {
      input.value = 0 + ''
      return
    } else {
      input.value = (Number(isNaN(innerMoney) ? 0 : innerMoney) + money).toLocaleString()
    }
  }
  const handleInputChange = (e: any) => {
    const input = document.getElementById('money') as HTMLInputElement;
    const money = parseInt(input.value.replace(/,/g, ''))
    if (isNaN(money)) {
      input.value = 0 + ''
    } else if (money < 0) {
      input.value = 0 + ''
    } else {
      input.value = money.toLocaleString()
    }
  }
  const detectMobileDevice = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile;
  };

  const handlePopupVaccInfo = () => {
    const isMobile = detectMobileDevice();
    
    if (virtualAccount && virtualAccount.amount === -1) {
      return openDialog({
        title: '고객센터 문의',
        text: '충전계좌는 고객센터로 문의 부탁드리겠습니다.',
        disableBackdropClick: true
      });
    }

    if (isMobile) {
      const _windowInstance = window.open('', 'popupvcc');

      if (!_windowInstance) {
        return openDialog({
          title: '팝업실패',
          text: '팝업 생성에 실패하였습니다.<br/>회원님께서 딥포커를 팝업 차단해제 처리하셔야 합니다.',
          disableBackdropClick: true
        });
      }

      setWindowInstance(_windowInstance);
    }
    else {
      const winOpt = `width=${popupSize.width},height=${popupSize.height},scrollbars=yes,resizable=yes`;
      const _windowInstance = window.open('', 'popupvcc', winOpt);

      if (!_windowInstance) {
        return openDialog({
          title: '팝업실패',
          text: '팝업 생성에 실패하였습니다.<br/>회원님께서 딥포커를 팝업 차단해제 처리하셔야 합니다.',
          disableBackdropClick: true
        });
      }
  
      _windowInstance.onresize = () => {
        _windowInstance.resizeTo(popupSize.width, popupSize.height);
      };

      setWindowInstance(_windowInstance);
    }

    setAccountView(true);
  };

  const closePopup = () => {
    setAccountView(false);
    setWindowInstance(null);
  };

  return <DepositWrapper>
    {
      virtualAccount &&
      accountView &&
      windowInstance &&
      <PopupWindow
        windowInstance={windowInstance}
        closeWindowPortal={closePopup}
        title="입금계좌"
      >
        <AccountVirtualPopup 
          bank={virtualAccount?.vacc.bank}
          accountNo={virtualAccount?.vacc.vacc}
          amount={virtualAccount?.amount}
          accountName={virtualAccount?.vacc.accountName}
          addedAt={virtualAccount?.addedAt}
          closeWindow={closePopup}
        />
      </PopupWindow>
    }
    <div className='content'>
      <div className='card'>
        <div className='title'>
          충전방법 및 주의사항
        </div>
        <div className='content' dangerouslySetInnerHTML={{
          __html: info.replace(/\n/g, '<br>')
        }}>
        </div>
      </div>
      {
        !showVaccPopupButton && <div className='card'>
          <div className='title'>
            충전금액
          </div>
          <div className='money-input-wrapper'>
            <div className='input-wrapper'>
              <img className='point' src='/images/ic_point.png'/>
              <input id='money' type='text' className='input' placeholder={"금액을 입력해주세요"}
                onChange={handleInputChange}/>
            </div>
            <div className='money-grid'>
              <div className='money'
                onClick={() => handleSelectMoney(10000)}>
                1만원
              </div>
              <div className='money'
                onClick={() => handleSelectMoney(50000)}>
                5만원
              </div>
              <div className='money'
                onClick={() => handleSelectMoney(100000)}>
                10만원
              </div>
              <div className='money'
                onClick={() => handleSelectMoney(500000)}>
                50만원
              </div>
              <div className='money'
                onClick={() => handleSelectMoney(1000000)}>
                100만원
              </div>
              <div className='money'
                onClick={() => handleSelectMoney(0)}>
                정정
              </div>
            </div>
          </div>
        </div>
      }
      {
        showVaccPopupButton && 
        <div className='button button2' onClick={handlePopupVaccInfo}>
          입금계좌확인
        </div>
      }
      {
        !showVaccPopupButton && <div className='virtual-account'>
          <div className='button' onClick={handleCharge}>
            충전신청
          </div>
        </div>
      }
    </div>
    <HorizontalBar/>
    <DelHistoryButtonWrapper>
      <div className='button' onClick={handleRemoveAllHistory}>
        기록 전체삭제
      </div>
    </DelHistoryButtonWrapper>
    <HistoryWrapper>
      <div className='title'>
        충전내역
      </div>
      <div className='content'>
        {
          transactionList.filter(x => x.type === TX_TYPE.deposit).map((_, index) => {
            return <div key={index} className='item'>
              <div className='info'>
                <div className='money'>
                  {/* 계좌번호 : {_.vacc.vacc},
                  은행 : {_.vacc.bank},
                  {_.vacc.accountName ? "예금주 : " + _.vacc.accountName : ""}<br/> */}
                  {/* 입금액 :  */}
                  {(_.amount ?? 0) > 0 ? '+' : ''}{(_.amount ?? 0).toLocaleString()}
                </div>
                <div className='createdAt'>
                  {
                    moment(_.addedAt).format('MM월 DD일 HH:mm')
                  }
                </div>
              </div>
              <div className='status'>
                {_.status === TransactionStatus.pending && '입금확인중'}
                {_.status === TransactionStatus.hold && '입금확인중'}
                {_.status === TransactionStatus.accepted && '입금완료'}
                {_.status === TransactionStatus.rejected && '입금실패'}
              </div>
            </div>
          })
        }
        {
          transactionList.filter(x => x.type === TX_TYPE.deposit).length === 0 && <EmptyWrapper>
            거래내역이 없습니다.
          </EmptyWrapper>
        }
      </div>
    </HistoryWrapper>
  </DepositWrapper>
}
const WithdrawWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    flex-grow: 1;
  }
  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 24px 16px;
    gap: 24px;

    > .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
      @media ${MEDIA_DESKTOP} {
        max-width: 470px;
        align-items: center;
      }

      > .title-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        > .title {
          color: #FFF;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }

        > .sub {
          opacity: 0.4;
          color: #FFF;
          text-align: right;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }
      }


      > .content {
        width: 100%;
        padding: 10px 12px;
        color: #DADADA;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        border-radius: 8px;
        background: #262838;
        @media ${MEDIA_DESKTOP} {
          border-radius: 8px;
          background: #F6F6F6;
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          padding: 24px;
        }
      }

      > .rolling-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 10px 12px;
        border-radius: 8px;
        background: rgb(38, 40, 56);
        @media ${MEDIA_DESKTOP} {
          background: #F6F6F6;
          padding: 24px;
        }

        > .rolling {
          color: rgb(218, 218, 218);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 16px;
          }
        }

        > .description {
          color: rgb(218, 218, 218);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 14px;
          }
        }
      }

      > .password-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        > .title {
          color: #FFF;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }

        > .input {
          width: 100%;
          outline: none;
          background: none;
          color: #FFF;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
          text-align: left;
          border-radius: 6px;
          padding: 10px 12px;
          border: 1px solid #25262D;
          height: 36px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            height: 44px;
          }
        }
      }

      > .money-input-wrapper {
        width: 100%;
        @media ${MEDIA_DESKTOP} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        > .input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 6px;
          border: 1px solid #25262D;
          height: 36px;
          padding-left: 10px;
          padding-right: 12px;
          @media ${MEDIA_DESKTOP} {
            border-radius: 4px;
            border: 1px solid rgba(24, 24, 24, 0.30);
            height: 44px;
          }

          > .point {
            width: 16px;
            height: 16px;
            object-fit: contain;
            @media ${MEDIA_DESKTOP} {
              width: 24px;
              height: 24px;
            }
          }

          > input {
            outline: none;
            background: none;
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
            border: none;
            text-align: right;
            @media ${MEDIA_DESKTOP} {
              color: var(--Black, #181818);
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%; /* 16px */
            }
          }
        }

        > .money-grid {
          margin-top: 8px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 8px;
          @media ${MEDIA_DESKTOP} {
            max-width: 426px;
          }

          > .money {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 36px;
            color: #FFF;
            text-align: center;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.5px;
            border-radius: 4px;
            background: #25262D;
            @media ${MEDIA_DESKTOP} {
              border-radius: 28px;
              border: 1px solid var(--Black, #181818);
              background: none;
              color: var(--Black, #181818);
              text-align: center;
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.5px;
            }

            &:last-child {
              grid-column: 1 / 4;
              color: #FFF;
              @media ${MEDIA_DESKTOP} {
                color: var(--Black, #181818);
              }
            }
          }
        }
      }
    }


    .button {
      cursor: pointer;
      width: 100%;
      height: 36px;
      border-radius: 6px;
      background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        max-width: 280px;
        height: 44px;
        border-radius: 22px;
        background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
      }
    }

    .button.trade-money {
      background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
    }

    .button.selected {
      border-radius: 6px;
      border: 1px solid var(--Point-Red, #E31D1D);
      background: rgba(227, 29, 29, 0.50);
    }

    .button.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`
const Withdraw = ({info, rollingPercentage}: { info: string; rollingPercentage: number }) => {
  const {openDialog} = useDialog();
  const [transactionList, setTransactionList] = useState<UserTransactionData[]>([]);
  const [refresh, setRefresh] = useState<number>(new Date().getTime())
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null)
  const transactionPasswordRef = useRef<HTMLInputElement>(null)
  const [rollingAchieve, setRollingAchieve] = useState<number>(0)
  const [disableButton, setDisableButton] = useState<boolean>(false)

  useEffect(() => {
    setNeedUiRefreshListener(() => {
      requestMyInfo().then(res => {
        setMyInfo(res.info)
        setRollingAchieve(Math.floor(res.info.rollingAchieve * (rollingPercentage / 100)))
        setRefresh(new Date().getTime())
      })
    })
    return () => setNeedUiRefreshListener(null);
  }, []);

  useEffect(() => {
    requestMyInfo().then(res => {
      setMyInfo(res.info)
      setRollingAchieve(Math.floor(res.info.rollingAchieve * (rollingPercentage / 100)))
    })
  }, []);

  useEffect(() => {
    if (myInfo) {
      requestGetTransactionList().then(res => {
        setTransactionList(res.list)
      })
    }
  }, [myInfo, refresh]);
  const handleWithdraw = () => {
    if (!(myInfo && myInfo.rollingMoney >= rollingAchieve)) return
    const transactionPassword = transactionPasswordRef.current?.value
    const money = document.getElementById('money') as HTMLInputElement;
    const moneyValue = parseInt(money.value.replace(/,/g, ''))
    if (transactionPassword === undefined || transactionPassword === '') {
      openDialog({
        title: '환전신청',
        text: '환전 비밀번호를 입력해주세요.',
        disableBackdropClick: true,
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        }
      })
      return
    }
    if (transactionList && transactionList.length > 0) {
      const pendingTransaction = transactionList.filter(x => x.status === TransactionStatus.pending || x.status === TransactionStatus.hold);

      if (pendingTransaction.length> 0) {
        openDialog({
          title: '환전신청',
          text: '이미 충전신청이나 환전신청이 진행중입니다.<br/>모두 완료된 상태에서 신청 부탁드리겠습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true)
          }
        })
        return
      }
    }
    if (moneyValue < 0) {
      openDialog({
        title: '환전신청',
        text: '환전금액을 확인해주세요.',
        disableBackdropClick: true,
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        }
      })
      return
    }
    if (moneyValue < 10000) {
      openDialog({
        title: '환전신청',
        text: '최소 환전금액은 1만원입니다.',
        disableBackdropClick: true,
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        }
      })
      return
    }
    if (moneyValue % 10000 !== 0) {
      openDialog({
        title: '환전신청',
        text: '환전금액은 1만원 단위로 가능합니다.',
        disableBackdropClick: true,
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        }
      })
      return
    }
    playSFX(Sounds.SFX_WINDOW_OPEN, true)
    const loading = startLoading()

    requestRequestUserTransaction(TX_TYPE.withdraw, moneyValue, sha256(transactionPassword)).then(res => {
      if (res.result) {
        money.value = '';

        openDialog({
          title: '환전신청',
          text: '환전신청이 완료되었습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true)
          }
        })
      } else {
        openDialog({
          title: '환전신청',
          text: res.msg ?? '환전신청이 실패하였습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true)
          }
        })
      }
    }).finally(() => {
      stopLoading(loading)
      setRefresh(new Date().getTime())
    })
  }

  useEffect(() => {
    const condition1 = myInfo && rollingAchieve <= myInfo.rollingMoney;
    const condition2 = (
      transactionList.length > 0 && [TransactionStatus.accepted, TransactionStatus.pending].includes(transactionList[0].status)
    ) ? (moment().diff(moment(transactionList[0].addedAt), 'hours') >= 2)
      : true
    if (condition1 && condition2) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [myInfo, rollingAchieve, transactionList])

  const handleRemoveAllHistory = () => {
    requestRemoveAllTransactionList(TX_TYPE.withdraw).then(res => {
      if (res.success) {
        setTransactionList([]);
      }
    });
  };

  const handleSelectMoney = (money: number) => {
    playSFX(Sounds.SFX_CLICK, true)
    const input = document.getElementById('money') as HTMLInputElement;
    // 1만원 단위로 변경
    const innerMoney = Math.floor(parseInt(input.value.replace(/,/g, '')) / 10000) * 10000
    if (money === -1) {
      if (myInfo) {
        input.value = (Math.floor(myInfo.money / 10000) * 10000).toLocaleString()
      }
    } else if (money === 0) {
      input.value = 0 + ''
      return
    } else {
      input.value = (Number(isNaN(innerMoney) ? 0 : innerMoney) + money).toLocaleString()
    }
  }
  const handleInputChange = (e: any) => {
    const input = document.getElementById('money') as HTMLInputElement;
    const money = parseInt(input.value.replace(/,/g, ''))
    if (isNaN(money)) {
      input.value = 0 + ''
    } else if (money < 0) {
      input.value = 0 + ''
    } else {
      input.value = money.toLocaleString()
    }
  }
  return <WithdrawWrapper>
    <div className='content'>
      <div className='card'>
        <div className='title-wrapper'>
          <div className='title'>
            환전방법 및 주의사항
          </div>
        </div>
        <div className='content' dangerouslySetInnerHTML={{
          //\n을 <br>로 변경
          __html: info.replace(/\n/g, '<br>')
        }}>
        </div>
      </div>
      <div className='card'>
        <div className='title-wrapper'>
          <div className='title'>
            출금 신청금액
          </div>
          <div className='sub'>
            {myInfo?.bank} {myInfo?.bankAccount}
          </div>
        </div>
        <div className='money-input-wrapper'>
          <div className='input-wrapper'>
            <img className='point' src='/images/ic_point.png'/>
            <input id='money' type='text' className='input' placeholder={"금액을 입력해주세요"} onChange={handleInputChange}/>
          </div>
          <div className='money-grid'>
            <div className='money'
              onClick={() => handleSelectMoney(10000)}>
              1만원
            </div>
            <div className='money'
              onClick={() => handleSelectMoney(50000)}>
              5만원
            </div>
            <div className='money'
              onClick={() => handleSelectMoney(100000)}>
              10만원
            </div>
            <div className='money'
              onClick={() => handleSelectMoney(500000)}>
              50만원
            </div>
            <div className='money'
              onClick={() => handleSelectMoney(1000000)}>
              100만원
            </div>
            <div className='money'
              onClick={() => handleSelectMoney(-1)}>
              MAX
            </div>
            <div className={'money'}
              onClick={() => handleSelectMoney(0)}>
              정정
            </div>
          </div>
        </div>
        <div className='password-wrapper'>
          <div className='title'>환전 비밀번호</div>
          <input ref={transactionPasswordRef} className='input' type='password' placeholder='8자리 이내 숫자'/>
        </div>

        <div className={'button trade-money ' + (disableButton ? 'disabled' : '')} onClick={handleWithdraw}>
          환전신청
        </div>
        <div className='rolling-wrapper'>
          <div className='rolling'>롤링 달성
            : {(myInfo?.rollingMoney ?? 0).toLocaleString()} / {rollingAchieve.toLocaleString()}</div>
          <div className='description'>롤링 달성 시 환전신청 가능합니다.</div>
        </div>
      </div>
    </div>
    <HorizontalBar/>
    <DelHistoryButtonWrapper>
      <div className='button' onClick={handleRemoveAllHistory}>
        기록 전체삭제
      </div>
    </DelHistoryButtonWrapper>
    <HistoryWrapper>
      <div className='title'>
        환전내역
      </div>
      <div className='content'>
        {
          transactionList.filter(x => x.type === TX_TYPE.withdraw).map((_, index) => {
            return <div key={index} className='item'>
              <div className='info'>
                <div className='money'>
                  -{_.amount.toLocaleString()}
                </div>
                <div className='status'>
                  {_.status === TransactionStatus.pending && '대기중'}
                  {_.status === TransactionStatus.hold && '대기중'}
                  {_.status === TransactionStatus.accepted && '환전완료'}
                  {_.status === TransactionStatus.rejected && '환전실패'}
                </div>
              </div>
              <div className='createdAt'>
                {
                  moment(_.addedAt).format('MM월 DD일 HH:mm')
                }
              </div>
            </div>
          })
        }
        {
          transactionList.filter(x => x.type === TX_TYPE.withdraw).length === 0 && <EmptyWrapper>
            거래내역이 없습니다.
          </EmptyWrapper>
        }
      </div>
    </HistoryWrapper>
  </WithdrawWrapper>
}

interface TradeProps {
  showCustomerCenter: () => void;
}

const Trade = ({showCustomerCenter}: TradeProps) => {
  const [conn] = useSocketIsConnect();
  const navigate = useNavigate();
  useEffect(() => {
    if (conn.isLogin) {

    } else {
      navigate('/main')
    }
  }, [conn]);
  const [selectedTab, setSelectedTab] = useState<"deposit" | "withdraw">("deposit");
  const [data, setData] = useState<{
    depositArticle: string,
    withdrawalArticle: string,
    rollingPercentage: number
  } | null>(null)
  useEffect(() => {
    requestGetDWArticle().then((res: {
      depositArticle: string,
      withdrawalArticle: string,
      rollingPercentage: number
    }) => {
      setData(res)
    })
  }, []);
  return <TradeWrapper>
    <div className='tab-wrapper'>
      <div className={'tab' + (selectedTab === 'deposit' ? ' selected' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        setSelectedTab('deposit')
      }}>
        충전신청
      </div>
      <div className={'tab' + (selectedTab === 'withdraw' ? ' selected' : '')}
           onClick={() => {
             playSFX(Sounds.SFX_TOGGLE, true)
             setSelectedTab('withdraw')
           }}>
        환전신청
      </div>
    </div>
    <div className='inner'>
      {
        selectedTab === 'deposit' &&
        <Deposit info={data?.depositArticle ?? ''} showCustomerCenter={showCustomerCenter}/>
      }
      {
        selectedTab === 'withdraw' &&
        <Withdraw rollingPercentage={data?.rollingPercentage ?? 0} info={data?.withdrawalArticle ?? ''}/>
      }
    </div>
  </TradeWrapper>
}

export default Trade;
