import styled from "styled-components";
import {
  requestCheckSMSCode,
  requestFindId,
  requestFindIdSMSCode,
  requestFindPasswordSMSCode,
  requestResetPassword,
  requestGetHQCode
} from "../api";
import useDialog from "../hooks/useDialog";
import {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import {sha256} from "js-sha256";
import CustomerCenter from "./CustomerCenter";

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--Background, #151515);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  >.content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      gap: 12px;
      >.image {
        img {
          width: 100px;
          height: 100px;
        }
      }
      >.text {
        color: #fff;
        font-size: 15px;
      }
      >.link-box {
        display: flex;
        color: #fff;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        width: 100%;
        >.item {
          width: 50%;
          border: 1px solid rgb(124 124 124);
          padding: 20px;
          font-size: 13px;
          text-align: center;
          background: #212121;
          img {
            width: 110px;
            height: 110px;
          }
          .fsdfsd {
            font-weight: bolder;
            padding-top: 5px;
            color: #d1d1d1;
          }
        }
        >.right {
          border-left: 0;
          .fsdfsd {
            color: #3d9fff;
          }
        }
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`
const InputItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
  }
`
const InputWrapper = styled.input`
  width: 100%;
  height: 48px;
  padding: 10px 12px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  text-align: left;
  outline: none;
  border: none;
  border-radius: 2px;
  background: #1A1A1A;
  

  &::placeholder {
    opacity: 0.5;
  }
`
const InputOutWrapper = styled.div`
  width: 100%;
  height: 48px;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  background: #1A1A1A;

  > input {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: none;
    text-align: left;
    outline: none;
    border: none;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    &::placeholder {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
    &:focus{
      background: none;
      outline: none;
    }
  }


  > .button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    border-radius: 15px;
    border: 1px solid var(--Point-Mint, #169E7A);
    background: rgba(22, 158, 122, 0.50);
  }
  >.button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  >.value {
    color: #FFF;
    opacity: 0.5;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }
`
const SignUpButton = styled.div<{disabled: boolean}>`
  margin-top: 8px;
  width: 100%;
  max-width: 300px;
  height: 48px;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  
  ${p => p.disabled ? `
  cursor: not-allowed;
  background: #25262D;
  color: rgba(255, 255, 255, 0.5);
  `: `
  cursor: pointer;
  color: #FFF;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  `}
`

interface LoginProps {
  onClose: () => void;
}
const SEND_SMS_INTERVAL = 120
const FindPW = ({onClose}: LoginProps) => {
  const {openDialog} = useDialog()
  const [isCheckPhone, setIsCheckPhone] = useState(false)
  const [lastSendTime, setLastSendTime] = useState(0)
  const timerRef = useRef<HTMLDivElement>(null)
  const [isInputCheckNumber, setIsInputCheckNumber] = useState(false)
  const [result, setResult] = useState<boolean | null>(null)
  const [userData, setUserData] = useState<{
    uid: string,
    name: string,
    phone: string,
    code: number
  } | null>(null)
  const [isUseSms, setIsUseSms] = useState<boolean>(true);
  const [showGuestCs, setShowGuestCs] = useState(false);

  useEffect(() => {
    requestGetHQCode().then(res => {
      //이름은 requestGetHQCode이지만 signup에 필요한 모든 initialze 데이터를 불러옵니다.
      if (res.isUseSms)
        setIsUseSms(true);
      else
        setIsUseSms(false);
    })
  }, []);

  const handleSendSMS = () => {
    const phone = document.getElementById('phone') as HTMLInputElement
    if (!phone.value) {
      openDialog({
        text: '핸드폰 번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    requestFindIdSMSCode(phone.value).then(res => {
      openDialog({
        title: '인증번호 발송',
        text: '인증번호가 발송되었습니다.',
        disableBackdropClick: true
      })
      setLastSendTime(moment().unix())
    })
  }
  useEffect(() => {
    if(lastSendTime > 0){
      const interval = setInterval(() => {
        const now = moment().unix()
        const diff = now - lastSendTime
        if(diff >= SEND_SMS_INTERVAL){
          clearInterval(interval)
          setLastSendTime(0)
          if(timerRef.current){
            timerRef.current.innerText = ``
          }
        }else{
          const left = SEND_SMS_INTERVAL - diff
          const min = Math.floor(left / 60).toString().padStart(2, '0')
          const sec = (left % 60).toString().padStart(2, '0')
          if(timerRef.current){
            timerRef.current.innerText = `${min}:${sec}`
          }
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [lastSendTime, timerRef]);

  const handleFindPW = () => {
    const uid = document.getElementById('uid') as HTMLInputElement
    const phone = document.getElementById('phone') as HTMLInputElement
    const name = document.getElementById('name') as HTMLInputElement
    const phoneCheck = document.getElementById('phone-check') as HTMLInputElement
    if(!uid){
      openDialog({
        text: '아이디를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!name.value) {
      openDialog({
        text: '이름을 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!phone.value) {
      openDialog({
        text: '핸드폰 번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!phoneCheck.value) {
      openDialog({
        text: '인증번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    const checkNumber = parseInt(phoneCheck.value)
    requestCheckSMSCode(phone.value, checkNumber).then(res => {
      console.log(res)
      setResult(res.result)
      if(res.result){
        setUserData({
          uid: uid.value,
          name: name.value,
          phone: phone.value,
          code: checkNumber
        })
      }
    })
  }
  const handleResetPassword = () => {
    const password = (document.getElementById('password') as HTMLInputElement).value
    const passwordCheck = (document.getElementById('password-check') as HTMLInputElement).value
    if (!password) {
      openDialog({
        text: '비밀번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!passwordCheck) {
      openDialog({
        text: '비밀번호 확인을 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (password !== passwordCheck) {
      openDialog({
        text: '비밀번호가 일치하지 않습니다.',
        disableBackdropClick: true
      })
      return
    }
    if (password) {
      if (password.length < 8 || password.length > 20) {
        openDialog({
          text: '비밀번호는 8~20자리 이내로 입력해주세요.',
          disableBackdropClick: true
        })
        return;
      }
      if (!/^[a-zA-Z0-9]*$/.test(password)) {
        openDialog({
          text: '비밀번호는 영문, 숫자만 입력 가능합니다.',
          disableBackdropClick: true
        })
        return;
      }
    }
    if(userData){
      requestResetPassword(userData.uid, userData.name, userData.phone, userData.code, sha256(password)).then(res => {
        console.log(res)
        if(res.result){
          openDialog({
            title: '비밀번호 변경 안내',
            component: <div>
              비밀번호가 변경되었습니다.<br/>
              로그인 후 이용가능합니다.
            </div>,
            confirmText: '로그인하러 가기',
            disableBackdropClick: true,
            onConfirm: () => {
              onClose()
            }
          })
        }
      })
    }else {
      return;
    }
  }
  return <LoginWrapper>
    <Header>
      <img className='close' src='/images/ic_close.png' onClick={() => onClose()}/>
      <div className='title'>비밀번호 찾기</div>
    </Header>
    <div className='content-wrapper'>
      {isUseSms ? (
        result === true ? 
          <div className='content'>
          <InputItemWrapper>
            <div className='title'>
              비밀번호
            </div>
            <InputWrapper id='password' type='password' placeholder='8~20자리 이내 영문, 숫자'/>
          </InputItemWrapper>
          <InputItemWrapper>
            <div className='title'>
              비밀번호 확인
            </div>
            <InputWrapper id='password-check' type='password' placeholder='비밀번호를 다시 입력하세요'/>
          </InputItemWrapper>
          <SignUpButton disabled={userData === null} onClick={handleResetPassword}>비밀번호 변경하기</SignUpButton>
        </div> : <div className='content'>
          <InputItemWrapper>
            <InputWrapper id='name' placeholder='실명'/>
          </InputItemWrapper>
          <InputItemWrapper>
            <InputWrapper id='uid' placeholder='아이디' onChange={e => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
            }}/>
          </InputItemWrapper>
          <InputOutWrapper>
            <input id='phone' placeholder='핸드폰번호를 입력해주세요' disabled={isCheckPhone}/>
            <div className={'button' + (lastSendTime > 0 ? ' disabled' : '')} onClick={handleSendSMS}>인증하기</div>
          </InputOutWrapper>
          <InputItemWrapper>
            <InputOutWrapper>
              <input id='phone-check' placeholder='인증번호 입력' disabled={isCheckPhone} onChange={(event) => {
                if (event.target.value.length > 0) {
                  setIsInputCheckNumber(true)
                } else {
                  setIsInputCheckNumber(false)
                }
              }}/>
              <div className='value' ref={timerRef}/>
            </InputOutWrapper>
            {
              lastSendTime > 0 && <div className='result-row'>
                <div className='result'>
                  {result === false ? `인증번호 혹은 실명,아이디를 잘못되었습니다.` : ``}
                </div>
                <div className='resend'/>
              </div>
            }
          </InputItemWrapper>
          <SignUpButton disabled={!isInputCheckNumber} onClick={handleFindPW}>비밀번호 찾기</SignUpButton>
        </div>
      ) : (
        <div className='content'>
          <div className='image'>
            <img src="/images/fixing.png" alt="수리중"/>
          </div>
          <div className='text'>
            <p>현재 기능상의 문제로 <strong>자동 비밀번호 찾기 서비스를 사용하실 수 없습니다.</strong></p>
            <p>아래 경로를 통해서 저희에게 연락주시면 확인 후 안내해드리겠습니다.</p>
          </div>

          <div className='link-box'>
            <div className='item left' onClick={()=>{
              setShowGuestCs(true);
            }}>
              <div>
                <img src="/images/guest1.png" alt="비회원"/>
              </div>
              <div className='fsdfsd'>비회원 고객센터</div>
            </div>
            <div className='item right' onClick={()=>{
              window.open('https://t.me/deeppoker0', '_blank')
            }}>
              <img src="/images/telegram.png" alt="텔레그램"/>
              <div className='fsdfsd'>텔레그램 고객센터</div>
            </div>
          </div>
        </div>
      )}
    </div>
    {showGuestCs && <CustomerCenter onClose={() => {
      setShowGuestCs(false);
    }} />}
  </LoginWrapper>
}

export default FindPW;
