import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {RoomInfo } from "../../dataset";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import moment from "moment";

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-right: 1px solid #E31D1D;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }

  >.contentWrapper {
    overflow-y: scroll;
  }
`;

const Title = styled.div`
  margin: 10px;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 10px;
  margin-top: 20px;
  >strong {
    color: #ffdd0b;
  }
  @media ${MEDIA_DESKTOP} {
    font-size: 16px;
  }
`;

const Title2 = styled.div`
  padding: 30px;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.4px;
  >strong {
    color: #ffdd0b;
    margin-left: 5px;
  }
  display: block;
  text-align: center;
  >span {
    display: block;
  }
  >strong {
    display: block;
  }
  @media ${MEDIA_DESKTOP} {
    justify-content: center;
    display: flex;
    font-size: 20px;
  }
`;
const Title3 = styled.div`
  padding: 30px;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.4px;
  >strong {
    color: #ffdd0b;
    margin-left: 5px;
  }
  display: block;
  text-align: center;
  >span {
    display: block;
  }
  >strong {
    display: block;
  }
  @media ${MEDIA_DESKTOP} {
    justify-content: center;
    display: flex;
    font-size: 20px;
    padding-top: 4px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: rgba(227, 29, 29, 0.3);

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
      height: 72px;
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .gap {
    width: 48px;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
    }
  }
`;

const InGameList = styled.div`
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgb(22, 22, 24);
  margin-top: 0;
  >.header {
    align-items: center;
    height: 38px;
    background: #1d1d1d;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
  }
  >.content {
    padding-top: 10px;
    padding-bottom: 10px;
    @media ${MEDIA_DESKTOP} {
      padding: 0;
    }
    >.item {
      display: flex;
      align-items: center;
      font-size: 14px;
      &+.item {
        padding-top: 10px;
        @media ${MEDIA_DESKTOP} {
          padding-top: 0;
        }
      }
      @media ${MEDIA_DESKTOP} {
        height: 36px;
        font-size: 15px;
      }
    }
    >.sdfnsndf {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 14px;
      >img {
        width: 80px;
      }
    }
  }
  >.fsdf {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    >.item {
      height: initial;
      display: block;
      padding-top: 18px!important;
      padding-left: 12px;
      padding-right: 12px;
      display: block;
      padding-bottom: 8px;
      @media ${MEDIA_DESKTOP} {
        padding: 20px!important;
      }
      >.dsdfdf {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${MEDIA_DESKTOP} {
          display: block;
        }
        >.asnfansf {
          width: 48px;
          height: 48px;
          position: absolute;
          z-index: 3;
          background: url(/images/nes/game_account_profile.png) 0% 0% / 100% 100% no-repeat;
          @media ${MEDIA_DESKTOP} {
            width: 70px;
            height: 70px;
          }
        }
        
        >img {
          width: 48px;
          height: 48px;
          border-radius: 70px;
          @media ${MEDIA_DESKTOP} {
            width: 70px;
            height: 70px;
          }
        }
      }
      >.dsfmsdf {
        text-align: center;
        font-size: 12px;
        padding-top: 2px;
        @media ${MEDIA_DESKTOP} {
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
  .line1 {
    width: 30%;
    text-align: center;
    font-size: 13px;
    >.dssdbnf {
      font-size: 12px;
      padding-top: 2px;
    }
    .dsdfdf {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${MEDIA_DESKTOP} {
        display: block;
      }
      >.asnfansf {
        width: 48px;
        height: 48px;
        position: absolute;
        z-index: 3;
        background: url(/images/nes/game_account_profile.png) 0% 0% / 100% 100% no-repeat;
        @media ${MEDIA_DESKTOP} {
          width: 70px;
          height: 70px;
        }
      }
      >img {
        width: 48px;
        height: 48px;
        border-radius: 70px;
        @media ${MEDIA_DESKTOP} {
          width: 70px;
          height: 70px;
        }
      }
    }
    
    @media ${MEDIA_DESKTOP} {
      font-size: 15px;
      width: 40%;
    }
  }
  .line2 {
    width: 35%;
    text-align: center;
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
  .line3 {
    width: 35%;
    text-align: center;
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
  .line4 {
    width: 35%;
    text-align: center;
    >.sdfsd {
      color: #ff2a2a;
    }
    >.sdfsd2 {
      color: #21ff21;
    }
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
`;

function GameStatusBoard(
  {
    room,
    onClose
  }: {
    room?:RoomInfo|null|undefined
    onClose?: () => void;
  }
) {
  const orientation = useScreenOrientation();

  return <Wrapper>
     <Header>
      {
        orientation === 'portrait' ? <>
          <img className="back" src="/images/ic_header_back.svg" onClick={(e)=>{onClose && onClose()}}/>
          <div className="title">게임방 상황보기</div>
          <div className="gap"/>
        </> : <>
          <div className="gap"/>
          <div className="title">게임방 상황보기</div>
          <img className="back" src="/images/ic_header_close.svg" onClick={(e)=>{onClose && onClose()}}/>
        </>
      }
    </Header>
    <div className="contentWrapper">
      <Title2><span>게임방 현재</span><strong>{room ? moment().diff(moment(room.closeTime.replace(".000Z", "").replace("T", " ")), 'hours') : 0}시간 게임중</strong></Title2>
      <Title3><span>현재 게임 방의 라운드</span><strong>{room ? room.handNumber : 0}회</strong></Title3>
      <Title>게임중인 유저({room ? room.players.length : 0}명)</Title>
      <InGameList>
        <div className="header">
          <div className="line1">
            <span>플레이어</span>
          </div>
          {
            orientation === 'portrait' ? 
            <div className="line2">
              <span>바이인(핸드수)</span>
            </div> :
            <div className="line2">
              <span>바이인</span>
            </div>
          }
          {
            orientation === 'portrait' ? <></> :
            <div className="line3">
              <span>핸드</span>
            </div>
          }
          <div className="line4">
            <span>결과</span>
          </div>
        </div>
        <div className="content">
          {
            room && room.players.length === 0 ? (
              <div className="sdfnsndf">
                <img src="/images/nes/empty3.png" alt="" />
                <div>플레이중인 회원이 없습니다.</div>
              </div>
            ) : 
              room && room.players.map((p, i) => (
                <div className="item" key={`status-item-${i}`}>
                  {
                    orientation === 'portrait' ? (
                      <>
                      <div className="line1">
                        <div className="dsdfdf">
                          <div className="asnfansf"/>
                          <img src={p.profileImg} />
                        </div>
                        <div className="dssdbnf">{p.nickname}</div>
                      </div>
                      <div className="line2">
                        <span>{p.buyin.toLocaleString()} ({p.handsCount.toLocaleString()}회)</span>
                      </div>
                      <div className="line4">
                        {
                          p.stackSize - p.buyin < 0 ? (
                            <span className='sdfsd'>{(p.stackSize - p.buyin).toLocaleString()}</span>
                          ) : (
                            <span className='sdfsd2'>+{(p.stackSize - p.buyin).toLocaleString()}</span>
                          )
                        }
                      </div>
                      </>
                    ) : (
                      <>
                      <div className="line1">
                        <span>{p.nickname}</span>
                      </div>
                      <div className="line2">
                        <span>{p.buyin.toLocaleString()}</span>
                      </div>
                      <div className="line3">
                        <span>{p.handsCount.toLocaleString()}</span>
                      </div>
                      <div className="line4">
                        {
                          p.stackSize - p.buyin < 0 ? (
                            <span className='sdfsd'>{(p.stackSize - p.buyin).toLocaleString()}</span>
                          ) : (
                            <span className='sdfsd2'>+{(p.stackSize - p.buyin).toLocaleString()}</span>
                          )
                        }
                      </div>
                      </>
                    )
                  }
                </div>
              ))
          }
        </div>
      </InGameList>
      <Title>관전중인 유저({room ? room.spectator.length : 0}명)</Title>
      {
        room && room.spectator.length > 0 ? (
          <InGameList>
            <div className="content fsdf">
            {
              room.spectator.map((p, i) => (
                <div className="item" key={`statuass-item-${i}`}>
                  <div className="dsdfdf">
                    <div className="asnfansf"/>
                    <img src={p.profileImg} />
                  </div>
                  <div className="dsfmsdf">
                    <span>{p.nickname}</span>
                  </div>
                </div>
              ))
            }
            </div>
          </InGameList>
        ) : <></>
      }
    </div>
  </Wrapper>;
}

export default GameStatusBoard;
