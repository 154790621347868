import {useCallback, useEffect, useRef} from "react";
import {wait} from "../utils/common";
import {GamePlayer, ROOM_JOIN_STATUS, winnerModel} from "../dataset";
import useScreenOrientation from "./useScreenOrientation2";
import {playSFX, Sounds} from "../utils/sound";
import {minimumChips, moveChipsSound} from "../utils/chip";

const TABLE_PORTRAIT_ORIGIN_WIDTH = 904 / 1.8;
const TABLE_PORTRAIT_ORIGIN_HEIGHT = 1408 / 2.2;
const TABLE_LANDSCAPE_ORIGIN_WIDTH = 3252/1.95;
const TABLE_LANDSCAPE_ORIGIN_HEIGHT = 1900/1.95;

const RECTS_SEATS_PORTRAIT = [
  [0.28, 0.87],
  [0.20, 0.635],
  [0.20, 0.428],
  [0.20, 0.253],
  [0.34, 0.096],
  [0.66, 0.096],
  [0.80, 0.253],
  [0.80, 0.428],
  [0.80, 0.635],
];
const RECTS_DEALER_BUTTON_PORTRAIT = [
  [0.408, 0.765],
  [0.344, 0.66],
  [0.344, 0.455],
  [0.344, 0.277],
  [0.42, 0.17],
  [0.58, 0.17],
  [0.654, 0.277],
  [0.654, 0.455],
  [0.654, 0.66],
];
const RECTS_PLAYER_POT_PORTRAIT = [
  [0.42, 0.792],
  [0.35, 0.625],
  [0.35, 0.398],
  [0.35, 0.243],
  [0.34, 0.18],
  [0.66, 0.18],
  [0.66, 0.243],
  [0.66, 0.398],
  [0.66, 0.625],
];
const COMMUNITY_CARDS_Y_PORTRAIT = 0.537;
const MY_CARDS_Y_PORTRAIT = 0.8;
const POTS_Y_PORTRAIT = 0.434;
const STATUSBAR_Y_PORTRAIT = 0.434;
const LOGO_Y_PORTRAIT = 0.392;
const TABLE_HOLE_Y_PORTRAIT = 0.5;

const RECTS_SEATS_LANDSCAPE = [
  [0.35, 0.878],
  [0.16, 0.72],
  [0.12, 0.44],
  [0.19, 0.21],
  [0.5, 0.16],
  [0.80, 0.21],
  [0.88, 0.44],
  [0.84, 0.72],
  [0.64, 0.84],
];
const RECTS_DEALER_BUTTON_LANDSCAPE = [
  [0.33, 0.65],
  [0.33, 0.55],
  [0.27, 0.47],
  [0.33, 0.36],
  [0.50, 0.35],
  [0.65, 0.40],
  [0.68, 0.47],
  [0.70, 0.60],
  [0.56, 0.67],
];
const RECTS_PLAYER_POT_LANDSCAPE = [
  [0.35, 0.685],
  [0.27, 0.59],
  [0.22, 0.43],
  [0.29, 0.27],
  [0.50, 0.31],
  [0.71, 0.27],
  [0.78, 0.43],
  [0.75, 0.59],
  [0.63, 0.685],
];

const RECTS_SEATS_LANDSCAPE_MOBILE = [
  [0.35, 0.84],
  [0.14, 0.73],
  [0.04, 0.44],
  [0.17, 0.24],
  [0.5, 0.21],
  [0.83, 0.24],
  [0.96, 0.44],
  [0.86, 0.73],
  [0.64, 0.84],
];

const RECTS_PLAYER_POT_LANDSCAPE_MOBILE = [
  [0.36, 0.70],
  [0.215, 0.65],
  [0.17, 0.47],
  [0.23, 0.35],
  [0.50, 0.325],
  [0.715, 0.27],
  [0.845, 0.47],
  [0.76, 0.68],
  [0.61, 0.70],
];
const COMMUNITY_CARDS_Y_LANDSCAPE = 0.564;
const MY_CARDS_Y_LANDSCAPE = 0.8;
const POTS_Y_LANDSCAPE = 0.42;
const STATUSBAR_Y_LANDSCAPE = 0.431;
const LOGO_Y_LANDSCAPE = 0.348;
const TABLE_HOLE_Y_LANDSCAPE = 0.5;

function useGameLayout(
  {
    dealerIndex,
    maxTableMember,
    pots
  }: {
    dealerIndex: number
    maxTableMember: number
    pots: number[]
  }
) {
  const tableRectRef = useRef<{
    x: number,
    y: number,
    w: number,
    h: number
  }>({
    x: 0,
    y: 0,
    w: 0,
    h: 0
  });

  const prevDealerIndex = useRef<number>(dealerIndex);
  const {orientation, device} = useScreenOrientation();

  const rearrangeLayout = () => {
    const container = document.getElementsByClassName('game-table')[0] as HTMLDivElement;
    if (!container || container.offsetHeight == 0) {
      return;
    }
    const isPortrait = orientation === 'portrait';
    const tableOriginWidth = isPortrait ? TABLE_PORTRAIT_ORIGIN_WIDTH : TABLE_LANDSCAPE_ORIGIN_WIDTH;
    const tableOriginHeight = isPortrait ? TABLE_PORTRAIT_ORIGIN_HEIGHT : TABLE_LANDSCAPE_ORIGIN_HEIGHT;

    let width = container.offsetWidth;
    let height = container.offsetHeight;

    const containerAspect = container.offsetWidth / container.offsetHeight;
    const originAspect = tableOriginWidth / tableOriginHeight;
    if (containerAspect > originAspect) {
      width = height * (tableOriginWidth / tableOriginHeight);
    } else if (containerAspect < originAspect) {
      height = width * (tableOriginHeight / tableOriginWidth);
    }

    const left = container.offsetLeft + (container.offsetWidth - width) / 2;
    const top = container.offsetTop + (container.offsetHeight - height) / 2;
    const cx = left + width / 2;
    const cy = top + height / 2;

    tableRectRef.current = {
      x: left,
      y: top,
      w: width,
      h: height
    };

    const scale = device === 'pc' ? Math.min(width / tableOriginWidth, isPortrait ? 1.5 : 1.5) : Math.min(width / tableOriginWidth, isPortrait ? 1.5 : 0.883411);

    const scale_map_mobile_landscape:any = {
      'logoWrapper': width / tableOriginWidth,
      'dealer-button': 0.44535345,
      'communityCardsWrapper': width / tableOriginWidth
    };

    const scale_map_pc_landscape:any = {
      'logoWrapper': width / tableOriginWidth,
      'dealer-button': 0.44535345,
      'communityCardsWrapper': width / tableOriginWidth
    };

    const scale_map_portrait:any = {
      'game-seat': 1
    };

    function position(arr: any, idx: number, left: number, top: number, type="") {
      try {
        if (!isPortrait && device === 'mobile' && typeof scale_map_mobile_landscape[type] !== 'undefined') {
          arr[idx].style.transform = "scale(" + scale_map_mobile_landscape[type] +")";
        }
        else if (isPortrait && scale_map_portrait[type]) {
          arr[idx].style.transform = "scale(" + scale * 1.17 +")";
        }
        else {
          arr[idx].style.transform = "scale(" + scale + ")";
        }
        
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const logoWrapper = document.getElementsByClassName("logo-wrap") as any;
    const isInTournament = logoWrapper && logoWrapper[0] && logoWrapper[0].classList && logoWrapper[0].classList.value.indexOf('isTournament') > -1;

    const communityCardsWrapper = document.getElementsByClassName("community-cards-wrapper") as any;
    const fieldPotsWrapper = document.getElementsByClassName("field-pots") as any;
    const myCardsWrapper = document.getElementsByClassName("my-cards-wrapper") as any;
    const dealerButton = document.getElementsByClassName("dealer-button") as any;
    const tableHole = document.getElementsByClassName("table-hole") as any;
    const StatusWrapper = document.getElementsByClassName("status-wrap") as any;
    
    
    const rabbitHuntButtonWrapper = document.getElementsByClassName("rabbit-hunt-button") as any;
    const rabbitHuntButtonY = isPortrait ? 0.587 : 0.654;
    const rabbitHuntCardLength = rabbitHuntButtonWrapper[0].getAttribute('data-length');
    
    const communityCardsY = isPortrait ? COMMUNITY_CARDS_Y_PORTRAIT : COMMUNITY_CARDS_Y_LANDSCAPE;
    const myCardsY = isPortrait ? MY_CARDS_Y_PORTRAIT : MY_CARDS_Y_LANDSCAPE;
    const fieldPotsY = isPortrait ? POTS_Y_PORTRAIT : POTS_Y_LANDSCAPE;
    const statusBarY = isPortrait ? STATUSBAR_Y_PORTRAIT : STATUSBAR_Y_LANDSCAPE;
    const logoInTableY = isPortrait ? LOGO_Y_PORTRAIT : LOGO_Y_LANDSCAPE;
    const tableHoleY = isPortrait ? TABLE_HOLE_Y_PORTRAIT : TABLE_HOLE_Y_LANDSCAPE;
    position(communityCardsWrapper, 0, cx, top + height * communityCardsY + (!isPortrait && device === 'mobile' ? 10 : 0), 'communityCardsWrapper');
    position(myCardsWrapper, 0, cx, top + height * myCardsY);
    position(fieldPotsWrapper, 0, cx, top + height * fieldPotsY * (isPortrait && isInTournament ? 0.7 : 1));
    position(tableHole, 0, cx, top + height * tableHoleY);

    if (isPortrait) {
      if (StatusWrapper && StatusWrapper.length > 0) {
        position(StatusWrapper, 0, cx, top + height * statusBarY * (isPortrait && isInTournament ? 0.98 : 1));
      }
    }
    else {
      if (StatusWrapper && StatusWrapper.length > 0) {
        StatusWrapper[0].style.transform = null;
        StatusWrapper[0].style.left = null;
        StatusWrapper[0].style.top = null;
      }
    }
    position(logoWrapper, 0, cx, top + height * logoInTableY * (isPortrait && isInTournament ? 0.7 : 1), 'logoWrapper');

    let cxOffset = 0;

    if (rabbitHuntCardLength === '0') {
      Array.from(rabbitHuntButtonWrapper).forEach((element:any) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
    }
    else {
      Array.from(rabbitHuntButtonWrapper).forEach((element:any) => {
        if (!element.classList.contains("active")) {
          element.classList.add("active");
        }
      });

      if (rabbitHuntCardLength === '3') {
        cxOffset = isPortrait ? 40 : 90;
      }
      else if (rabbitHuntCardLength === '2') {
        cxOffset = isPortrait ? -60 : -140;
      }
      else if (rabbitHuntCardLength === '1') {
        cxOffset = isPortrait ? -80 : -182;
      }

      position(rabbitHuntButtonWrapper, 0, cx - cxOffset, top + height * rabbitHuntButtonY + (!isPortrait && device === 'mobile' ? 10 : 0));
    }

    /*// 인게임 버튼 사이즈 조정
    if (inGameButtonWrapper[0] && inGameButtonWrapper[0].style.transform !== 'none') {
      const buttonScale = Math.min(scale, 1);
      inGameButtonWrapper[0].style.transformOrigin = `right bottom`;
      inGameButtonWrapper[0].style.transform = `scale(${buttonScale}, ${buttonScale})`
    }*/

    const seats = document.getElementsByClassName("game-seat") as any;
    const pots = document.getElementsByClassName("game-player-pot") as any;
    const seatRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_SEATS_PORTRAIT :  device === 'mobile' ? RECTS_SEATS_LANDSCAPE_MOBILE : RECTS_SEATS_LANDSCAPE ));
    const potRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_PLAYER_POT_PORTRAIT : device === 'mobile' ? RECTS_PLAYER_POT_LANDSCAPE_MOBILE : RECTS_PLAYER_POT_LANDSCAPE));
    const dealerBtnRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_DEALER_BUTTON_PORTRAIT : RECTS_DEALER_BUTTON_LANDSCAPE));

    let deleteSeatIndices: number[] = [];
    if (maxTableMember === 5) {
      deleteSeatIndices = isPortrait ? [8, 6, 4, 2] : [8, 7, 6, 5];
    } else if (maxTableMember === 6) {
      deleteSeatIndices = isPortrait ? [8,6,2] : [7, 6, 5];
    } else if (maxTableMember === 8) {
      if (isPortrait) {
        deleteSeatIndices = [8]
      }
    }

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      potRects.splice(seatIndex, 1);
      dealerBtnRects.splice(seatIndex, 1);
    }

    // 좌석과 팟 위치 설정
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1], 'game-seat');
      position(pots, i, left + width * potRects[i][0], top + height * potRects[i][1]);
    }

    // 딜러버튼 위치 설정
    if (dealerIndex !== -1) {
      // 딜러버튼의 위치가 바뀐 경우 transition 활성화
      if (prevDealerIndex.current !== dealerIndex) {
        playSFX(Sounds.SFX_DEALER_BUTTON_MOVE);
        dealerButton[0].style.transition = 'all 1s ease-in-out';
        prevDealerIndex.current = dealerIndex;
      } else if (dealerButton[0].style.transition !== '') {
        dealerButton[0].style.transition = '';
      }
      position(dealerButton, 0, left + width * dealerBtnRects[dealerIndex][0], top + height * dealerBtnRects[dealerIndex][1], 'dealer-button');
    } else {
      position(dealerButton, 0, cx, cy, 'dealer-button');
    }
  };

  const coinMove = useCallback(async (from: DOMRect, to: DOMRect, amount: number) => {
    const wrapper = document.querySelector(".coin-move-wrapper");
    if (!wrapper) {
      return;
    }

    const chips = minimumChips(amount);
    for (let {size, amount} of chips) {
      for (let i = 0; i < amount; i++) {
        const div = document.createElement("div");
        div.className = "move-coin";
        div.style.width = (orientation === 'portrait' ? 14 : 20) + 'px';
        div.style.height = (orientation === 'portrait' ? 13 : 19) + 'px';
        div.style.backgroundImage = `url(/images/chip_${size}.png)`;
        div.style.transform = `translate(${from.x + from.width / 2 - 7}px,${from.y + from.height / 2 - 7}px)`;
        wrapper.append(div);

        setTimeout(async () => {
          try {
            await wait(10);
            div.style.transform = `translate(${to.x + to.width / 2 - 7}px,${to.y + to.height / 2 - 7}px)`;
            await wait(200);
            div.style.opacity = "0";
            await wait(200);
            div.remove();
          } finally {
          }
        }, 10);

        await wait(50);
      }

    }
  }, [orientation]);

  const chipsMove = useCallback((source: HTMLElement, to: DOMRect) => {
    const wrapper = document.querySelector(".coin-move-wrapper");
    if (!wrapper) {
      return;
    }

    const div = document.createElement("div");
    div.innerHTML = source.outerHTML;
    div.className = "move-coin";

    const from = source.getBoundingClientRect();
    const scale =  from.width / source.clientWidth;

    div.style.transform = `translate(${from.x}px,${from.y}px) scale(1)`;
    const child = div.firstElementChild as HTMLElement | null
    if (child) {
      child.style.transform = `scale(${scale})`
      child.style.transformOrigin = '0% 0%';
    }

    wrapper.append(div);

    (async () => {
      try {
        await wait(10);
        div.style.transform = `translate(${to.x + to.width / 2 - from.width / 2}px,${to.y + to.height / 2 - from.height / 2}px) scale(${scale * 0.5})`;
        await wait(500);
        div.remove();
      } finally {
      }
    })();
  }, [orientation]);

  const moveCoinToGamePot = useCallback(() => {
    const fieldPot = document.querySelector(".field-pot");
    if (fieldPot) {
      const potElems = [...document.querySelectorAll(".game-player-pot .chips") as any];
      for (let potElem of potElems) {
        const amount = Number(potElem.getAttribute('data-amount'));
        if (amount > 0) {
          playSFX(moveChipsSound(amount));
          chipsMove(
            potElem,
            fieldPot.getBoundingClientRect(),
          );
        }
      }
    }
  }, []);

  const moveCoinAnte = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.querySelector(`.seat-${userId}`);
    const fieldPot = document.querySelector(".field-pot");
    if (seat && fieldPot) {
      playSFX(moveChipsSound(amount));
      await coinMove(
        seat.getBoundingClientRect(),
        fieldPot.getBoundingClientRect(),
        amount
      );
    }
  }, []);

  const moveCoinRake = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.querySelector(`.seat-${userId}`);
    const tableHole = document.querySelector(".table-hole");
    if (seat && tableHole) {
      playSFX(moveChipsSound(amount));
      await coinMove(
        seat.getBoundingClientRect(),
        tableHole.getBoundingClientRect(),
        amount,
      );
    }
  }, []);

  const moveCoinToWinners = useCallback((winners: winnerModel[]) => {
    const fieldPot = document.querySelector(".field-pot.chips") as HTMLElement | null;
    if (fieldPot) {
      for (let winner of winners) {
        const seat = document.querySelector(`.seat-${winner.userId}`);
        if (seat) {
          playSFX(moveChipsSound(winner.amount));
          chipsMove(
            fieldPot,
            seat.getBoundingClientRect(),
          );
        }
      }

      fieldPot.setAttribute('data-amount', '0');
    }
  }, []);
  const getAllCardElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck > .player-card') as NodeListOf<HTMLElement>;
  }, []);

  const getAllDeckElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck') as NodeListOf<HTMLElement>;
  }, []);

  const getAllCommunityCardElements = useCallback(() => {
    return document.querySelectorAll('.community-card') as NodeListOf<HTMLElement>;
  }, []);

  const hideAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '0';
    }
  }, []);

  const showAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '1';
    }
  }, []);

  const showRabbitHuntCard = useCallback((rabbitHuntCardLength:number) => {
    const container = document.getElementsByClassName('game-table')[0] as HTMLDivElement;
    if (!container || container.offsetHeight == 0) {
      return;
    }

    const isPortrait = orientation === 'portrait';
    const tableOriginWidth = isPortrait ? TABLE_PORTRAIT_ORIGIN_WIDTH : TABLE_LANDSCAPE_ORIGIN_WIDTH;
    const tableOriginHeight = isPortrait ? TABLE_PORTRAIT_ORIGIN_HEIGHT : TABLE_LANDSCAPE_ORIGIN_HEIGHT;

    let width = container.offsetWidth;
    let height = container.offsetHeight;

    const containerAspect = container.offsetWidth / container.offsetHeight;
    const originAspect = tableOriginWidth / tableOriginHeight;
    if (containerAspect > originAspect) {
      width = height * (tableOriginWidth / tableOriginHeight);
    } else if (containerAspect < originAspect) {
      height = width * (tableOriginHeight / tableOriginWidth);
    }

    const left = container.offsetLeft + (container.offsetWidth - width) / 2;
    const top = container.offsetTop + (container.offsetHeight - height) / 2;
    const cx = left + width / 2;

    tableRectRef.current = {
      x: left,
      y: top,
      w: width,
      h: height
    };

    const scale = device === 'pc' ? Math.min(width / tableOriginWidth, isPortrait ? 1.5 : 1.5) : 0.883411;

    const scale_map_mobile_landscape:any = {
      'logoWrapper': width / tableOriginWidth,
      'dealer-button': 0.44535345,
      'communityCardsWrapper': width / tableOriginWidth
    };

    function position(arr: any, idx: number, left: number, top: number, type="") {
      try {
        if (!isPortrait && device === 'mobile' && typeof scale_map_mobile_landscape[type] !== 'undefined') {
          arr[idx].style.transform = "scale(" + scale_map_mobile_landscape[type] +")";
        }
        else {
          arr[idx].style.transform = "scale(" + scale + ")";
        }
        
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }
    const rabbitHuntButtonWrapper = document.getElementsByClassName("rabbit-hunt-button") as any;
    const rabbitHuntButtonY = isPortrait ? 0.587 : 0.584;
    
    let cxOffset = 0;

    if (rabbitHuntCardLength === 3) {
      cxOffset = 39;
    }

    position(rabbitHuntButtonWrapper, 0, cx - cxOffset, top + height * rabbitHuntButtonY + (!isPortrait && device === 'mobile' ? 10 : 0));
  }, [orientation]);

  const resetPlayersCards = useCallback(() => {
    if (!tableRectRef.current) {
      return;
    }

    const cx = tableRectRef.current.x + tableRectRef.current.w / 2;
    const cy = tableRectRef.current.y + tableRectRef.current.h / 2;
    const decks = getAllDeckElements();

    // 모든 카드를 가운데로 모으기
    for (let i = 0; i < decks.length; i++) {
      const deck = decks[i];
      const cards = deck.querySelectorAll('.player-card') as NodeListOf<HTMLElement>;
      cards[0].style.transition = 'none';
      cards[1].style.transition = 'none';
      cards[0].style.opacity = '0';
      cards[1].style.opacity = '0';
      const deckRect = deck.getBoundingClientRect();
      const x = cx - deckRect.x - cards[0].offsetWidth / 2;
      const y = cy - deckRect.y - cards[0].offsetHeight / 2;
      cards[0].style.transform = `translate(${x}px, ${y}px) rotate(-360deg)`;
      cards[1].style.transform = `translate(${x - cards[1].offsetWidth}px, ${y}px) rotate(-360deg)`;
    }
  }, []);

  const dealCardsToPlayers = useCallback(async (players: GamePlayer[]) => {
    const cards0: HTMLElement[] = [];
    const cards1: HTMLElement[] = [];
    for (let i = 0; i < players.length; i++) {
      if (players[i].status !== ROOM_JOIN_STATUS.PLAYING) {
        continue;
      }

      const seat = players[i].seat;
      const cards = document.querySelectorAll(`.card-deck[data-seat="${seat}"] > .player-card`);
      if (cards.length !== 2) {
        console.error('card elements of seat ' + seat + ' are not found! cards.length : ' + cards.length);
        continue;
      }
      cards0.push(cards[0] as HTMLElement);
      cards1.push(cards[1] as HTMLElement);
    }

    // 카드 딜링 애니메이션 (transform 해제 시키기)
    const cards = [...cards0, ...cards1];
    for (let i = 0; i < cards.length; i++) {
      playSFX(Sounds.SFX_CARD_DIS, true);
      cards[i].style.transition = 'all 200ms linear';
      cards[i].style.opacity = '1';
      cards[i].style.transform = '';
      await new Promise<void>((r) => setTimeout(() => r(), 100));
    }

    {
      // 카드가 혹시라도 이동되지 않는 경우를 대비해 강제적으로 제 위치로 돌려놓는다.
      const cards = getAllCardElements();
      for (let i = 0; i < cards.length; i++) {
        cards[i].style.transition = '';
        cards[i].style.opacity = '1';
        cards[i].style.transform = '';
      }
    }
  }, []);

  useEffect(() => {
    const gameTableElem = document.querySelector('.game-table');
    if (!gameTableElem) {
      return;
    }

    rearrangeLayout();

    gameTableElem.addEventListener('load', rearrangeLayout);
    window.addEventListener('resize', rearrangeLayout);
    window.addEventListener('orientationchange', rearrangeLayout);

    return () => {
      gameTableElem.removeEventListener('load', rearrangeLayout);
      window.removeEventListener('resize', rearrangeLayout);
      window.removeEventListener('orientationchange', rearrangeLayout);
    };
  }, [dealerIndex, maxTableMember, pots, orientation]);

  return {
    tableRect: tableRectRef.current,
    moveCoinToGamePot,
    moveCoinToWinners,
    moveCoinAnte,
    moveCoinRake,
    getAllCardElements,
    getAllDeckElements,
    getAllCommunityCardElements,
    resetPlayersCards,
    hideAllCards,
    showAllCards,
    dealCardsToPlayers,
    showRabbitHuntCard
  };
}

export default useGameLayout;
