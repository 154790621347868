import styled from "styled-components";
import {startLoading, stopLoading} from "../components/Loading";
import {
  requestCheckNickname,
  requestCheckSMSCode,
  requestCheckUid,
  requestGetHQCode,
  requestSignUpSMSCode,
  requestUserSignUp
} from "../api";
import {useEffect, useState} from "react";
import {sha256} from "js-sha256";
import useDialog from "../hooks/useDialog";

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--Background, #151515);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      gap: 16px;
    }
  }
`
const Header = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`
const InputItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
  }

  > .description {
    color: #B4B4B4;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;

    > .bold {
      color: #FFF;
      font-weight: 600;
    }
  }
`
const SelectWrapper = styled.div`
  width: 100%;
  height: 40px;
  background: #1A1A1A;
  > select {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M7.73739 10L12.5005 15.25L17.2637 10" stroke="white" stroke-linecap="round" stroke-linejoin="round"/> </svg>') no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding: 10px 12px;
    padding-right: 2rem !important;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 48px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    text-align: left;
    &:focus {
      outline: none;
    }

    > option:disabled {
      opacity: 0;
    }

    > option {
      color: #000;
    }
  }
`
const InputOutWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 2px;
  background: #1A1A1A;

  > input {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: none;
    text-align: left;
    outline: none;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    text-align: left;
    
    &::placeholder {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
  }


  > .button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    border-radius: 15px;
    border: 1px solid var(--Point-Mint, #169E7A);
    background: rgba(22, 158, 122, 0.50);
  }
`
const InputWrapper = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  border: none;
  border-radius: 2px;
  background: #1A1A1A;
  text-align: left;
  outline: none;

  &::placeholder {
    opacity: 0.5;
  }
`

const SignUpButton = styled.div`
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  max-width: 300px;
  height: 48px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
`

interface LoginProps {
  onClose: () => void;
}

const Signup = ({onClose}: LoginProps) => {
  const {openDialog} = useDialog();
  const [isCheckPhone, setIsCheckPhone] = useState<boolean>(false);
  const [HQCode, setHQCode] = useState<string | null>(null);
  const [isUseSms, setIsUseSms] = useState<boolean>(false);
  const [statusVerifingPhone, setStatusVerifingPhone] = useState<number>(0);
  const [phoneCheck, setPhoneCheck] = useState<string>("");
  const [bankList, setBankList] = useState<string[]>([
    '서버로부터 로딩중입니다'
  ]);

  useEffect(() => {
    requestGetHQCode().then(res => {
      //이름은 requestGetHQCode이지만 signup에 필요한 모든 initialze 데이터를 불러옵니다.;
      setHQCode(res.HQcode)

      if (res.isUseSms)
        setIsUseSms(true);

      if (res.banks) {
        setBankList(res.banks);
      }
    })
  }, []);
  const handleSignUp = () => {
    const uid = (document.getElementById('uid') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const passwordCheck = (document.getElementById('password-check') as HTMLInputElement).value;
    const nickname = (document.getElementById('nickname') as HTMLInputElement).value;
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const phone = (document.getElementById('phone') as HTMLInputElement).value;
    const bank = (document.getElementById('bank') as HTMLInputElement).value;
    const account = (document.getElementById('account') as HTMLInputElement).value;
    const transactionPassword = (document.getElementById('transaction-password') as HTMLInputElement).value;
    const code = (document.getElementById('code') as HTMLInputElement).value;
    
    if (!uid) {
      openDialog({
        text: '아이디를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!password) {
      openDialog({
        text: '비밀번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!passwordCheck) {
      openDialog({
        text: '비밀번호 확인을 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!nickname) {
      openDialog({
        text: '닉네임을 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!name) {
      openDialog({
        text: '성명을 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!phone) {
      openDialog({
        text: '핸드폰번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (isUseSms && (statusVerifingPhone !== 2 || !phoneCheck)) {
      openDialog({
        text: '핸드폰번호 인증을 완료해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!bank) {
      openDialog({
        text: '은행을 선택해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!account) {
      openDialog({
        text: '계좌번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!transactionPassword) {
      openDialog({
        text: '환전 비밀번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (!code) {
      openDialog({
        text: '가입코드를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if (uid) {
      if (uid.length < 4 || uid.length > 16) {
        openDialog({
          text: '아이디는 4~16자리 이내로 입력해주세요.',
          disableBackdropClick: true
        })
        return;
      }
      if (!/^[a-zA-Z0-9]{4,16}$/.test(uid)) {
        openDialog({
          text: '아이디는 영문, 숫자만 입력 가능합니다.',
          disableBackdropClick: true
        })
        return;
      }
    }
    if (password) {
      if (password.length < 4 || password.length > 16) {
        openDialog({
          text: '비밀번호는 4~16자리 이내로 입력해주세요.',
          disableBackdropClick: true
        })
        return;
      }
      if (!/^[a-zA-Z0-9]{4,16}$/.test(password)) {
        openDialog({
          text: '비밀번호는 영문, 숫자만 입력 가능합니다.',
          disableBackdropClick: true
        })
        return;
      }
    }
    //닉네임 한글/영문 2자리에서 6자리까지
    if(nickname){
      if(nickname.length < 2 || nickname.length > 6){
        openDialog({
          text: '닉네임은 2~6자리 이내로 입력해주세요.',
          disableBackdropClick: true
        })
        return;
      }
      if(!/^[a-zA-Zㄱ-ㅎ가-힣]{2,6}$/.test(nickname)){
        openDialog({
          text: '닉네임은 한글, 영문만 입력 가능합니다.',
          disableBackdropClick: true
        })
        return;
      }
    }

    if (password !== passwordCheck) {
      openDialog({
        text: '비밀번호가 일치하지 않습니다.',
        disableBackdropClick: true
      })
      return;
    }
    if (transactionPassword) {
      if (transactionPassword.length < 4 || transactionPassword.length > 4) {
        openDialog({
          text: '환전 비밀번호는 4자리로 입력해주세요.',
          disableBackdropClick: true
        })
        return;
      }
    }
    const loading = startLoading();
    const profileImage = "/images/default_profile.png"
    //const profileImage =  (Math.floor(Math.random()*6)+1).toString()
    
    requestUserSignUp(
      uid, sha256(password), nickname, name, profileImage, phone, bank, account, Number(phoneCheck), code, sha256(transactionPassword)
    ).then(res => {
      console.log('res', res)
      if (res.result) {
        openDialog({
          text: '회원가입이 완료되었습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            onClose()
          }
        })
      } else {
        openDialog({
          text: res.error ? res.error : '회원가입에 실패하였습니다.',
          disableBackdropClick: true
        })
      }
    }).catch((err) => {
      console.log('err', err)
    }).finally(() => {
      stopLoading(loading);
    })
  }

  const handleCheckId = () => {
    if((document.getElementById('uid') as HTMLInputElement).value.length < 4 || (document.getElementById('uid') as HTMLInputElement).value.length > 16) {
      openDialog({
        text: '아이디는 4~16자리 이내로 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    requestCheckUid((document.getElementById('uid') as HTMLInputElement).value).then(res => {
      if (res.result === 1) {
        openDialog({
          text: '사용가능한 아이디입니다.',
          disableBackdropClick: true
        })
      } else {
        if (res.result === -2) {
          openDialog({
            text: '아이디에 한글은 사용이 불가능합니다.',
            disableBackdropClick: true
          })
        }
        else {
          openDialog({
            text: '이미 사용중인 아이디입니다.',
            disableBackdropClick: true
          })
        }
      }
    })
  }
  const handleCheckNickname = () => {
    const nickname = (document.getElementById('nickname') as HTMLInputElement).value
    if (!nickname) {
      openDialog({
        text: '닉네임을 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if(nickname.length < 2 || nickname.length > 6){
      openDialog({
        text: '닉네임은 2~6자리 이내로 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    if(!/^[a-zA-Zㄱ-ㅎ가-힣]{2,6}$/.test(nickname)){
      openDialog({
        text: '닉네임은 한글, 영문만 입력 가능합니다.',
        disableBackdropClick: true
      })
      return;
    }
    requestCheckNickname((document.getElementById('nickname') as HTMLInputElement).value).then(res => {
      if (res.result) {
        openDialog({
          text: '사용가능한 닉네임입니다.',
          disableBackdropClick: true
        })
      } else {
        openDialog({
          text: '이미 사용중인 닉네임입니다.',
          disableBackdropClick: true
        })
      }
    })
  }
  const handleSendSMS = () => {
    const phone = (document.getElementById('phone') as HTMLInputElement).value;
    if (!phone) {
      openDialog({
        text: '핸드폰번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return;
    }
    const loading = startLoading()
    requestSignUpSMSCode(phone).then(res => {
      if (res.result) {
        openDialog({
          text: '인증번호가 전송되었습니다.',
          disableBackdropClick: true
        })
        setStatusVerifingPhone(1);
      } else {
        if (res.error === 0) {
          openDialog({
            text: '이미 가입된 전화번호입니다.',
            disableBackdropClick: true
          })
        }
        else if (res.error === 1) {
          openDialog({
            text: '인증번호는 5분에 한번만 전송가능합니다. 최근에 요청하신 내역이 있어서 대기 부탁드리겠습니다.',
            disableBackdropClick: true
          })
        }
      }
    }).catch((err) => {
      console.log('err', err)
    }).finally(() => {
      stopLoading(loading)
    })
  }
  const handleCheckSMS = () => {
    const phone = (document.getElementById('phone') as HTMLInputElement).value;
    const checkCode = (document.getElementById('phone-check') as HTMLInputElement).value;
    const loading = startLoading()
    requestCheckSMSCode(phone, Number(checkCode)).then(res => {
      if (res.result) {
        openDialog({
          text: '인증되었습니다.',
          disableBackdropClick: true
        })
        setPhoneCheck(checkCode);
        setStatusVerifingPhone(2);
      } else {
        openDialog({
          text: '인증번호가 틀렸습니다.',
          disableBackdropClick: true
        })
      }
    }).finally(() => {
      stopLoading(loading)
    })
  }

  return <LoginWrapper>
    <Header>
      <img className='close' src='/images/ic_close.png' onClick={() => onClose()}/>
      <div className='title'>회원가입</div>
    </Header>
    <div className='content-wrapper'>
      <div className='content'>
        <InputItemWrapper>
          <div className='title'>
            아이디
          </div>
          <InputOutWrapper>
            <input  id='uid' type='text' placeholder='4~16자리 이내 영문, 숫자 ' onChange={e => {
              e.target.value = e.target.value.toLowerCase();
            }}/>
            <div className='button' onClick={handleCheckId}>중복확인</div>
          </InputOutWrapper>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            비밀번호
          </div>
          <InputWrapper id='password' type='password' placeholder='4~16자리 이내 영문, 숫자'/>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            비밀번호 확인
          </div>
          <InputWrapper id='password-check' type='password' placeholder='4~16자리 이내 영문, 숫자'/>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            닉네임
          </div>
          <InputOutWrapper>
            <input id='nickname' placeholder='닉네임을 입력해주세요' onChange={e =>{
              e.target.value = e.target.value;
            }}/>
            <div className='button' onClick={handleCheckNickname}>중복확인</div>
          </InputOutWrapper>

        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            성명
          </div>
          <InputWrapper id='name' placeholder='실명을 입력해주세요' onChange={e => {
            e.target.value = e.target.value;
          }}/>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            핸드폰번호
          </div>
          <InputOutWrapper>
            <input id='phone' placeholder='핸드폰번호를 입력해주세요' disabled={statusVerifingPhone !== 0}/>
            {
              isUseSms && statusVerifingPhone === 0 ? (
              <div className='button' onClick={handleSendSMS}>인증하기</div>
              ) : <></>
            }
            
          </InputOutWrapper>
          {
            isUseSms && statusVerifingPhone === 1 ? (
            <InputOutWrapper>
              <input id='phone-check' placeholder='인증번호 입력'/>
              <div className='button' onClick={handleCheckSMS}>인증확인</div>
            </InputOutWrapper>
            ) : <></>
          }
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            계좌번호
          </div>
          <SelectWrapper>
            <select  id='bank'>
              <option disabled={true}>은행선택</option>
              {
                bankList.map((bank, index) => {
                  return <option key={`bank-list-${index}`} value={bank}>{bank}</option>
                })
              }
            </select>
          </SelectWrapper>
          <InputWrapper type={"number"} id='account' placeholder='- 없이 계좌번호를 입력해주세요'/>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            환전 비밀번호
          </div>
          <InputWrapper type='number' id='transaction-password' placeholder='4자리 숫자'/>
        </InputItemWrapper>
        <InputItemWrapper>
          <div className='title'>
            가입코드
          </div>
          <InputWrapper id='code' placeholder='가입코드를 입력해주세요'/>
          {
            (HQCode && HQCode.length > 0) && <div className='description'>
              가입 코드가 없으면 <span className='bold'>{HQCode}</span>을 입력해주세요
            </div>
          }
        </InputItemWrapper>
        <SignUpButton onClick={handleSignUp}>가입하기</SignUpButton>
      </div>
    </div>
  </LoginWrapper>
}

export default Signup;
