import styled from "styled-components";
import useDialog from "../../../hooks/useDialog";
import {useEffect, useRef, useState} from "react";
import {requestFindId, requestFindIdSMSCode, requestGetHQCode} from "../../../api";
import moment from "moment/moment";
import CustomerCenter from "../../CustomerCenter";

const SEND_SMS_INTERVAL = 120

const FindIDModalWrapper = styled.div`
  width: 470px;
  height: 540px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .header {
    position: relative;
    width: 100%;
    height: 57px;
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > .title {
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 374px;
    margin-top: 40px;
    gap: 16px;
    p {
      margin: 0;
      &:last-child {
        margin-top: 6px;
      }
    }
    >.image {
      img {
        width: 100px;
        height: 100px;
      }
    }
    >.text {
      color: #474747;
      font-size: 15px;
    }
    >.link-box {
      display: flex;
      color: #fff;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      width: 100%;
      >.item {
        width: 50%;
        border: 1px solid #bbbbbb;
        padding: 20px;
        font-size: 13px;
        text-align: center;
        background: #f5f5f5;
        cursor: pointer;
        &:hover {
          background: #e2e2e2;
        }

        img {
          width: 110px;
          height: 110px;
        }
        .fsdfsd {
          font-weight: bolder;
          padding-top: 5px;
          color: #353535;
        }
      }
      >.right {
        border-left: 0;
        .fsdfsd {
          color: #3d9fff;
        }
      }
    }
  }
`
const SignUpButton = styled.div<{disabled: boolean}>`
  margin-top: 8px;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 24px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  ${p => p.disabled ? `
    cursor: not-allowed;
    opacity: 0.5;
  `: `
    cursor: pointer;
  `}
`
const InputItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  > .title {
    color: #181818;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
  }

  > .result-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > .result {
      color: #FF1D25;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  }
`
const InputWrapper = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  color: #181818;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  border-radius: 4px;
  border: 1px solid rgba(24, 24, 24, 0.30);
  text-align: left;
  outline: none;

  &::placeholder {
    opacity: 0.5;
  }
`
const InputOutWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(24, 24, 24, 0.30);

  > input {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: none;
    text-align: left;
    outline: none;
    border: none;
    color: #181818;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */

    &::placeholder {
      opacity: 0.5;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      background: none;
      outline: none;
    }
  }


  > .button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--Point-Mint, #169E7A);
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    border-radius: 15px;
    border: 1px solid var(--Point-Mint, #169E7A);
    background: rgba(22, 158, 122, 0.10);
  }

  > .button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  > .value {
    color: #FFF;
    opacity: 0.5;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }
`

interface LoginModalProps {
  onClose: () => void;
  onFindPW: () => void;
}

const LoginModal = ({
  onFindPW,
  onClose
}: LoginModalProps) => {
  const {openDialog} = useDialog()
  const [isCheckPhone, setIsCheckPhone] = useState(false)
  const [isUseSms, setIsUseSms] = useState<boolean>(true);
  const [lastSendTime, setLastSendTime] = useState(0)
  const timerRef = useRef<HTMLDivElement>(null)
  const [isInputCheckNumber, setIsInputCheckNumber] = useState(false)
  const [result, setResult] = useState(true)
  const [showGuestCs, setShowGuestCs] = useState(false);

  useEffect(() => {
    requestGetHQCode().then(res => {
      //이름은 requestGetHQCode이지만 signup에 필요한 모든 initialze 데이터를 불러옵니다.
      if (res.isUseSms)
        setIsUseSms(true);
      else
        setIsUseSms(false);
    })
  }, []);

  const handleSendSMS = () => {
    const phone = document.getElementById('phone') as HTMLInputElement
    if (!phone.value) {
      openDialog({
        text: '핸드폰 번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    requestFindIdSMSCode(phone.value).then(res => {
      openDialog({
        text: '인증번호가 발송되었습니다.',
        disableBackdropClick: true
      })
      setLastSendTime(moment().unix())
    })
  }
  useEffect(() => {
    if (lastSendTime > 0) {
      const interval = setInterval(() => {
        const now = moment().unix()
        const diff = now - lastSendTime
        if (diff >= SEND_SMS_INTERVAL) {
          clearInterval(interval)
          setLastSendTime(0)
          if (timerRef.current) {
            timerRef.current.innerText = ``
          }
        } else {
          const left = SEND_SMS_INTERVAL - diff
          const min = Math.floor(left / 60).toString().padStart(2, '0')
          const sec = (left % 60).toString().padStart(2, '0')
          if (timerRef.current) {
            timerRef.current.innerText = `${min}:${sec}`
          }
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [lastSendTime, timerRef]);

  const handleFindId = () => {
    const phone = document.getElementById('phone') as HTMLInputElement
    const name = document.getElementById('name') as HTMLInputElement
    const phoneCheck = document.getElementById('phone-check') as HTMLInputElement
    if (!name.value) {
      openDialog({
        text: '이름을 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!phone.value) {
      openDialog({
        text: '핸드폰 번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    if (!phoneCheck.value) {
      openDialog({
        text: '인증번호를 입력해주세요.',
        disableBackdropClick: true
      })
      return
    }
    const checkNumber = parseInt(phoneCheck.value)
    requestFindId(name.value, phone.value, checkNumber).then(res => {
      console.log(res)
      setResult(res.result)
      if (res.result) {
        openDialog({
          title: '아이디 안내',
          text: res.uid,
          confirm: true,
          disableBackdropClick: true,
          confirmText: '로그인',
          onConfirm: () => {
            onClose()
          },
          cancelText: '비밀번호 찾기',
          onCancel: () => {
            onClose();
            onFindPW();
          }
        });
      }
    })
  }
  return <FindIDModalWrapper>
    <div className='header'>
      <div className='title'>
        아이디 찾기
      </div>
      <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
    </div>
    
    {isUseSms ? (
      <div className='content'>
        <InputItemWrapper>
          <InputWrapper id='name' placeholder='실명'/>
        </InputItemWrapper>
        <InputOutWrapper>
          <input id='phone' placeholder='핸드폰번호를 입력해주세요' disabled={isCheckPhone}/>
          <div className={'button' + (lastSendTime > 0 ? ' disabled' : '')} onClick={handleSendSMS}>인증하기</div>
        </InputOutWrapper>
        <InputItemWrapper>
          <InputOutWrapper>
            <input id='phone-check' placeholder='인증번호 입력' disabled={isCheckPhone} onChange={(event) => {
              if (event.target.value.length > 0) {
                setIsInputCheckNumber(true)
              } else {
                setIsInputCheckNumber(false)
              }
            }}/>
            <div className='value' ref={timerRef}/>
          </InputOutWrapper>
          {
            lastSendTime > 0 && <div className='result-row'>
              <div className='result'>
                {result ? `` : `인증번호 혹은 실명을 잘못입력하였습니다.`}
              </div>
              <div className='resend'/>
            </div>
          }
        </InputItemWrapper>
        <SignUpButton disabled={!isInputCheckNumber} onClick={handleFindId}>아이디 찾기</SignUpButton>
      </div>
    ) : (
      <div className='content'>
        <div className='image'>
          <img src="/images/fixing.png" alt="수리중"/>
        </div>
        <div className='text'>
          <p>현재 기능상의 문제로 <strong>자동 아이디 찾기 서비스를 사용하실 수 없습니다.</strong></p>
          <p>아래 경로를 통해서 저희에게 연락주시면 확인 후 안내해드리겠습니다.</p>
        </div>

        <div className='link-box'>
          <div className='item left' onClick={()=>{
            setShowGuestCs(true);
          }}>
            <div>
              <img src="/images/guest1.png" alt="비회원"/>
            </div>
            <div className='fsdfsd'>비회원 고객센터</div>
          </div>
          <div className='item right' onClick={()=>{
            window.open('https://t.me/deeppoker0', '_blank')
          }}>
            <img src="/images/telegram.png" alt="텔레그램"/>
            <div className='fsdfsd'>텔레그램 고객센터</div>
          </div>
        </div>
      </div>
    )}
    {showGuestCs && <CustomerCenter onClose={() => {
      setShowGuestCs(false);
    }} />}
  </FindIDModalWrapper>
}
export default LoginModal;
